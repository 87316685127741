<template>
  <div v-if="promotionalVideo">
    <button class="play-button" v-on:click="videoModal = true">
      <img src="@/assets/images/play.png" alt="" />
    </button>

    <Modal v-if="videoModal" @close="videoModal = false">
      <div slot="title"><b>Video</b></div>
      <div slot="body">
        <video width="100%" height="200px" autoplay>
          <source :src="$filesURL + promotionalVideo" />
           {{ $t("profile.videoerr") }}
        </video>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/VideoModal.vue";

export default {
  data() {
    return {
      videoModal: false,
    };
  },
  name: "Videobtn",
  components: {
    Modal,
  },
  props: ["promotionalVideo"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
