import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { HTTP } from "@/main-source";
import Verify from "../views/setup/Verify.vue";
import Confirm from "../views/setup/Confirm.vue";
import Setup from "../views/setup/Setup.vue";
import Secure from "../views/setup/Secure.vue";
import Edit from "../views/setup/Edit.vue";
import Login from "../views/setup/Login.vue";
import ForgotPassword from "../views/setup/Sendcode.vue";
import NewPass from "../views/setup/NewPass.vue";
import Qr from "../views/Qr.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/:shortlink/verify/",
    name: "Verify",
    component: Verify
  },
  {
    path: "/:shortlink?/confirm",
    name: "Confirm",
    component: Confirm
  },
  {
    path: "/:shortlink?/setup",
    name: "Setup",
    component: Setup,
  },
  {
    path: "/:shortlink?/secure",
    name: "Secure",
    component: Secure,
    meta: {
      title: "Edit",
      requiresAuth: true,
    },
  },
  {
    path: "/:shortlink?/qr",
    name: "Qr",
    component: Qr,
  },
  {
    path: "/:shortlink?/edit",
    name: "Edit",
    component: Edit,
    meta: {
      title: "Edit",
      requiresAuth: true,
    },
  },
  {
    path: "/login/:passwordsucess?/",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/newpass",
    name: "Newpass",
    component: NewPass,
  },
  {
    path: "/:shortlink?",
    name: "Home",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth == true)) {
    let token = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).token : false;

    if (!token) {
      localStorage.removeItem("user");
      HTTP.defaults.headers.common.Authorization = "";
      next({
        path: "/login",
        query: to.query,
      });
    }

  }
  next();
});

export default router;
