<template>
  <div class="row pdf-area">
    <div class="col" v-if="pdfbuttonList.link">
      <a class="btn" :href="$filesURL + pdfbuttonList.link" target="_blank">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-file-earmark-pdf"
          viewBox="0 0 16 16"
        >
          <path
            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
          />
          <path
            d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"
          />
        </svg>
        <div>
          {{ pdfbuttonList.buttonText }}
        </div>
      </a>
    </div>

    <div class="col" v-if="promotionalVideo">
      <a class="btn" v-on:click="videoModalPro = true">
        <svg
          id="Group_669"
          data-name="Group 669"
          xmlns="http://www.w3.org/2000/svg"
          width="10.934"
          height="10.934"
          viewBox="0 0 10.934 10.934"
        >
          <path
            id="Path_671"
            data-name="Path 671"
            d="M1033.755,1052.248h-9.134a.9.9,0,0,0-.9.9v9.133a.9.9,0,0,0,.9.9h9.134a.9.9,0,0,0,.9-.9v-9.133A.9.9,0,0,0,1033.755,1052.248Zm-4.567,8.907a3.44,3.44,0,1,1,3.44-3.44A3.44,3.44,0,0,1,1029.188,1061.155Z"
            transform="translate(-1023.721 -1052.248)"
            fill="#3a4145"
          />
          <path
            id="Path_672"
            data-name="Path 672"
            d="M1069.322,1089.934l-2.635-1.521c-.276-.16-.5-.029-.5.29v3.043c0,.319.226.449.5.29l2.635-1.521A.306.306,0,0,0,1069.322,1089.934Z"
            transform="translate(-1061.964 -1084.756)"
            fill="#3a4145"
          />
        </svg>
        <div> {{ $t("profile.watch") }}</div>

        <Modal v-if="videoModalPro" @close="videoModalPro = false">
          <div slot="title"><b> {{ $t("profile.promotinolvideo") }}</b></div>
          <div slot="body" class="video-modal">
            
            <video
              width="100%"
              height="200px"
              autoplay
              v-if="!checkIfYoutubeLink(promotionalVideo)"
            >
              <source :src="$filesURL + promotionalVideo" />
              {{ $t("profile.videoerr") }}
            </video>
            <iframe
              width="100%"
              height="56%"
              v-if="checkIfYoutubeLink(promotionalVideo)"
              :src="returnYoutubeEmbedCode(promotionalVideo)"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </Modal>
      </a>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/VideoModal.vue";

export default {
  name: "PdfArea",
  components: {
    Modal,
  },
  props: ["pdfButton", "promotionalVideo"],

  data() {
    return {
      pdfbuttonList: null,
      videoModalPro: false,
    };
  },

  created() {
    this.pdfbuttonList = JSON.parse(this.pdfButton);
  },
  methods: {
    checkIfYoutubeLink(link) {
      if (link.includes("youtube")) {
        return true;
      }
      return false;
    },
    returnYoutubeEmbedCode(link) {
      if (link.includes("youtube")) {
        return "https://www.youtube.com/embed/" + link.split("v=")[1] + '?autoplay=1';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
