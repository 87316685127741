<template>
  <div class="bg">
 <BgSetup/>

 <ConfirmArea/>
 <Bottom/>


    
  </div>
  
</template>
<script>
import BgSetup from "@/components/setup/BgSetup.vue";
import ConfirmArea from "@/components/setup/ConfirmArea.vue";

import Bottom from "@/components/Bottom.vue";


export default {
  name: "Confirm",
  components: {
    BgSetup,
    Bottom,
    ConfirmArea  
  }
};
</script>
