<template>
  <div class="row info-section">
    <h4 class="section-heading" v-if="parsedbillingInfos.length > 0">
      {{ $t("profile.payment") }} <span>{{ $t("profile.informations") }} </span>
    </h4>
    <div
      class="box d-flex flex-column adress-section"
      v-if="parsedbillingInfos.length > 0"
    >
      <div class="accordion" id="accordionPanelsStayOpenExample2">
        <div
          class="accordion-item"
          v-for="(parsedbillingInfo, index) in parsedbillingInfos"
          :key="index"
        >
          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button
              class="accordion-button"
              :class="{ collapsed: index != 0 }"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#panelsStayOpens-collapseOne' + index"
              aria-expanded="true"
              :aria-controls="'#panelsStayOpens-collapseOne' + index"
            >
              {{ parsedbillingInfo.title }}
            </button>
          </h2>
          <div
            :id="'panelsStayOpens-collapseOne' + index"
            class="accordion-collapse collapse"
            :class="{ show: index === 0 }"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div class="accordion-body d-flex flex-column bank">
              <div class="row d-flex justify-content-between">
                <span class="fw-bold mb-2"> {{ $t("profile.bank") }} : </span>

                <span>
                  {{ parsedbillingInfo.bank }}
                </span>
              </div>
              <div class="row d-flex justify-content-between">
                <span class="fw-bold mb-2">
                  {{ $t("profile.accountholder") }}:
                </span>
                <span>
                  {{ parsedbillingInfo.recipientName }}
                  <img
                    src="@/assets/images/copy.svg"
                    class="copy-icon"
                    v-on:click="
                      copyToClipBoard(parsedbillingInfo.recipientName)
                    "
                    alt="Copy"
                  />
                </span>
              </div>

              <div class="row d-flex justify-content-between">
                <span class="fw-bold mb-2"> IBAN : </span>

                <span>
                  {{ parsedbillingInfo.iban }}
                  <img
                    src="@/assets/images/copy.svg"
                    class="copy-icon"
                    v-on:click="copyToClipBoard(parsedbillingInfo.iban)"
                    alt="Copy"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",
  props: ["billingInfos"],
  data() {
    return {
      parsedbillingInfos: [],
    };
  },
  created() {
    this.parsedbillingInfos = JSON.parse(this.billingInfos).filter((item) => {
      return item.isActive == true;
    });
  },
  methods: {
    copyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      let text = this.$i18n.locale == "tr" ? "Kopyalandı" : "Copied";
      this.$notify(text);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
