<template>
  <div class="bg-container">
    <img src="@/assets/images/header-bg.jpg" alt="">
           
  </div>
</template>

<script>
export default {
  name: 'bg-area',
  props: {
  }
}
</script>

