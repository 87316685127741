<template>
  <transition name="modal">
    <div class="modal-mask video-modal" @click.stop>
      <div class="modal-wrapper" @click="$emit('close')">
        <div class="modal-container" @click="$emit('close')" >
          <div class="modal-body" @click="$emit('close')">
            <i class="fas fa-times" @click="$emit('close')"></i>

            <slot name="body"> </slot>
          </div>
          <!-- <div class="modal-footer">
            <slot name="footer"></slot>
          </div> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "VideoModal",
  methods: {
    close: function () {
      this.$emit("close");
    },

  },
};
</script>
