<template>
  <div class="bg">
    <BgSetup />
    <LoginArea :passwordsucess="passwordsucess" />
    <Bottom />

    <router-link
      class="forgot-pass-button"
      :to="{
        name: 'forgotPassword',
      }"
      >{{ $t("setup.passforgot") }}</router-link
    >
  </div>
</template>
<script>
import BgSetup from "@/components/setup/BgSetup.vue";
import LoginArea from "@/components/setup/LoginArea.vue";
import Bottom from "@/components/Bottom.vue";

export default {
  name: "Login",
  data() {
    return {
      passwordsucess: this.$route.params.passwordsucess || false,
    };
  },
  components: {
    BgSetup,
    LoginArea,
    Bottom,
  },
};
</script>
