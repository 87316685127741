<template>
  <button class="setup-btn">
    {{ btnText }}
  </button>
</template>

<script>
export default {
  name: "BtnSetup",
  props: ["btnText"],
};
</script>
