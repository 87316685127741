<template>
  <div class="more-container" v-if="parsedHighLights.length > 0">

    <div class="text-area">
      <h4>{{ $t("profile.highlights") }}</h4>
    </div>

    <div class="container">
      <Slider :highlights="parsedHighLights" />
    </div>
  </div>
</template>

<script>
import Slider from "@/components/Slider.vue";

export default {
  name: "More",
  props: ["highlights"],
  components: {
    Slider,
  },
  data() {
    return {
      parsedHighLights: JSON.parse(this.highlights).filter((item) => {
        return item.isActive == true && item.image;
      }),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
