<template>
  <div class="headings">
    <Modal v-if="firmModalData" @close="firmModalData = false">
      <div slot="title">
        <b> {{ userData.firm.name }}</b>
      </div>
      <div slot="body">
        <p
          class="s-desc d-flex flex-column justify-content-center align-items-center firm-area"
        >
          <span class="firm-pp-container">
            <img class="firm-pp" :src="$filesURL + userData.firm.profileImg" />
          </span>

          <span v-if="userData.firm.name">
            <b> {{ userData.firm.name }}</b></span
          >
          <span v-if="userData.firm.address" class="p-1">
            {{ userData.firm.address }}</span
          >

          <span v-if="userData.firm.taxOffice">{{
            userData.firm.taxOffice
          }}</span>
          <span v-if="userData.firm.taxNo"> {{ userData.firm.taxNo }}</span>

          <span v-if="userData.firm.tel"> {{ userData.firm.tel }}</span>
          <span v-if="userData.firm.email">
            {{ userData.firm.email }}<br
          /></span>
          <span v-if="userData.firm.web"> {{ userData.firm.web }}</span>
          <span v-if="userData.firm.additionalInfos" class="d-none">
            <a
              href=""
              v-for="(parsedadditionalInfo, index) in parsedadditionalInfos"
              :key="index"
            >
              <img
                v-if="parsedadditionalInfo.type.type == 'Email'"
                src="@/assets/images/mail.svg"
                alt=""
              />
              <img
                v-if="parsedadditionalInfo.type.type == 'link'"
                src="@/assets/images/site.svg"
                alt=""
              />
              <img
                v-if="parsedadditionalInfo.type.type == 'tel'"
                src="@/assets/images/phone.svg"
                alt=""
              />
              {{ parsedadditionalInfo.value }}
            </a>
          </span>
        </p>
      </div>
      <div slot="footer">
        <button
          class="delete-btn delete-btn-two close-btn"
          @click="firmModalData = false"
        >
          {{ $t("profile.close") }}
        </button>
      </div>
    </Modal>

    <h1 class="name">{{ name }}</h1>
    <span v-if="userData.title" class="mt-1">{{ userData.title }}</span>
    <h2 v-if="userData.firmId" class="company">
      <button class="btn" @click="firmModal">{{ userData.firm.name }}</button>
    </h2>

    <span v-if="!userData.firmId" class="company">{{ userData.firmName }}</span>

    <div class="info">
      <span class="tel">
        <span class="country-code" v-if="countryCode == 'TR'">+90</span>

        <a v-if="countryCode == 'TR'" :href="'tel:+90' + tel">{{ tel }}</a>
        <a v-if="countryCode !== 'TR'" :href="'tel:' + tel">{{ tel }}</a>
      </span>
      <span class="mail">
        <a :href="'mailto:' + email" target="_blank"> {{ email }} </a></span
      >
      <span class="website">
        <a :href="'http://' + web" target="_blank"> {{ web }} </a></span
      >
    </div>

    <a
      class="setup-btn save-vcf"
      :download="userData.name + '.vcf'"
      @click="savedCount"
      :href="$apiURL + 'users/' + userData.shortlink + '/save'"
    >
      {{ $t("profile.save") }}</a
    >
  </div>
</template>

<script>
/* eslint-disable */
import { HTTP } from "@/main-source";
import Modal from "@/components/Bordered-Modal";
import BtnSetup from "@/components/setup/BtnSetup.vue";

var vCardsJS = require("vcards-js");
var vCard = vCardsJS();
export default {
  name: "Headings",
  components: {
    Modal,
  },
  props: ["name", "title", "tel", "email", "web", "countryCode", "userData"],
  data() {
    return {
      Adress: JSON.parse(this.userData.addresses),
      firmModalData: false,
      parsedadditionalInfos: [],
    };
  },
  created() {
    this.parsedadditionalInfos = JSON.parse(
      this.userData.firm.additionalInfos
    ).filter((item) => {
      return item.isActive == true;
    });
  },
  methods: {
    savedCount() {
      let savedUsers = JSON.parse(localStorage.getItem("savedUsers")) || [];
      if (!savedUsers.includes(this.userData.id)) {
        HTTP.post("/stats/" + this.userData.id).then(() => {
          savedUsers.push(this.userData.id);
          localStorage.setItem("savedUsers", JSON.stringify(savedUsers));
        });
      } else {
        console.log("Already Saved");
      }
    },
    firmModal() {
      this.firmModalData = true;
    },
  },
  watch: {
    web: function (value){
      if(value.includes("http://") || value.includes("https://")){
        this.web = value;
      }else{
        this.web = "http://" + value;
      }
    }
  }
};
</script>
