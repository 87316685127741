<template>
 
    <div class="bg-area">
        <div class="bg-image">
         <img class="mobile" src="@/assets/images/setup-bg.png" alt="">
         <img class="desktop" src="@/assets/images/hero.png" alt="">

         


        </div>
        <div class="logo-area">
        </div>
    </div>
  
</template>

<script>

export default {
  name: "BgSetup",
  props: {},
};
</script>


