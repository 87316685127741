<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="close">
        <div class="modal-container contracts" @click.stop.prevent>
          <div class="row head col-12">
            <div class="col">
              <!-- <i class="fas fa-exclamation-triangle"></i> -->
              <slot name="title"> {{ $t("profile.contract") }}</slot>
            </div>
            <div class="col close-icon">
              <i class="fas fa-times" @click="close"></i>
            </div>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
