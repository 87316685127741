<template>
  <div class="bottom-logo">
    <a href="https://quicktouch.com.tr/" rel="nofollow" target="_blank">
      <img src="@/assets/images/footer-logo.svg" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  props: {},
};
</script>
