<template>
  <div class="box-area">
    <div class="container">
      <div class="verify-area">
        <span class="s-heading">{{ $t("setup.passforgot") }}</span>
        <span class="s-desc">{{ $t("setup.forgotdesc") }}</span>
        <div v-if="errors || error" class="notice" role="alert">
          <span class="s-desc"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-exclamation-triangle"
            >
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
              ></path>
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
              ></path></svg
            >{{ $t("setup.checktel") }}<br
          /></span>
        </div>

        <vue-phone-number-input
          default-country-code="TR"
          v-model="tel"
          @update="updatePhoneNumber"
          class="pb-5"
          @keyup.enter="test"
        ></vue-phone-number-input>

        <btn-setup
          :btnText="isAgain ?  $i18n.locale == 'tr' ? 'Tekrar Gönder' : 'Send Again' : $i18n.locale == 'tr' ? 'Kodu Gönder' : 'Send Code'"
          class="negative-button"
          @click.native="test"
        ></btn-setup>
      </div>
    </div>

  </div>
</template>

<script>
import BtnSetup from "@/components/setup/BtnSetup.vue";
//
export default {
  data() {
    return {
      tel: null,
      validtel: null,
      telinf: null,
      error: false,
      telisvalid: false,
    };
  },
  props: ["errors", "isAgain"],
  name: "Sendcodearea",
  components: {
    BtnSetup,
  },

  methods: {
    updatePhoneNumber(data) {
      console.log(data);
      if (data.isValid) {
        console.log(data.nationalNumber);
        this.validtel = data.nationalNumber;
        this.telisvalid = data.isValid;
        this.error = false;
      }
    },
    test() {
      console.log(this.validtel);
      if (this.validtel != null) {
        let data = {
          tel: this.validtel,
        };

        this.$emit("sendcode", data);
      } else {
        this.error = true;
      }
    },
  },
};
</script>
