<template>
  <div class="bg">
    <Modal v-if="acikRizaMetni" @close="acikRizaMetni = false">
      <div slot="title">
        <b>{{ $t("setup.acikriza") }}</b>
      </div>
      <template slot="body">
        <iframe
          src="https://quicktouch.com.tr/acik-riza-metni/"
          frameborder="0"
        ></iframe>
      </template>
      <div slot="footer"></div>
    </Modal>
    <Modal v-if="aydinlatmaMetni" @close="aydinlatmaMetni = false">
      <div slot="title">
        <b>{{ $t("setup.aydinlatma") }}</b>
      </div>
      <template slot="body">
        <iframe
          src="https://quicktouch.com.tr/aydinlatma-metni/"
          frameborder="0"
        ></iframe>
      </template>
      <div slot="footer"></div>
    </Modal>

    <BgProfile />
    <div class="setup-container">
      <div class="container">
        <div class="setup-area">
          <!-- Create Profile -->
          <div v-if="!codeSent">
            <span class="s-heading">{{ $t("setup.createprofile") }}</span>
            <div class="form-group mt-2">
              <label>{{ $t("setup.name") }}</label>
              <input
                type="text"
                class="form-control"
                :placeholder="$i18n.locale == 'tr' ? 'İsim' : 'Name'"
                v-model="newUser.name"
                autocomplete="new-name"
                @blur="$v.newUser.name.$touch()"
                :class="{
                  'is-invalid': $v.newUser.name.$error,
                }"
              />
              <div class="invalid-feedback">{{ $t("setup.required") }}</div>
            </div>
            <div class="form-group mt-2">
              <label>Tel</label>
              <vue-phone-number-input
                v-model="phone"
                @update="setPhone"
                @blur="$v.newUser.tel.$touch()"
                :show-code-on-list="true"
                :default-country-code="newUser.countryCode"
                :class="{
                  'is-invalid': $v.newUser.tel.$error,
                }"
              />

              <div class="invalid-feedback">{{ $t("setup.required") }}</div>
            </div>
            <div class="form-group mt-2">
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                v-model="newUser.email"
                autocomplete="new-email"
                @blur="$v.newUser.email.$touch()"
                :class="{
                  'is-invalid': $v.newUser.email.$error,
                }"
              />
              <div class="invalid-feedback">{{ $t("setup.required") }}</div>
            </div>

            <div class="form-group mt-2">
              <label>{{ $t("setup.secmail") }}</label>
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                v-model="newUser.additionalInfos[0].value"
                autocomplete="new-email"
              />
            </div>
            <div class="form-group mt-2 mb-2">
              <label>{{ $t("setup.pass") }}</label>
              <div class="d-flex mb-2">
              <input
                :type="passInput"
                class="form-control pass-form"
                :placeholder="$i18n.locale == 'tr' ? 'Şifre' : 'Password'"
                v-model="newUser.password"
                autocomplete="new-password"
                @blur="$v.newUser.password.$touch()"
                :class="{
                  'is-invalid': $v.newUser.password.$error,
                }"
              />
                <button v-on:click="showPass" class="show-pass-btn">
            <svg
              v-if="passInput == 'password'"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
              />
              <path
                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
              />
            </svg>
            <svg
              v-if="passInput == 'text'"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eye-slash"
              viewBox="0 0 16 16"
            >
              <path
                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
              />
              <path
                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
              />
              <path
                d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
              />
            </svg>
          </button>
                        </div>

              <div class="invalid-feedback">{{ $t("setup.required") }}</div>
            </div>
            <!-- Check -->
            <div
              class="form-check"
              :class="[error && !isAydinlatmaMetni ? 'border-red' : '']"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="isAydinlatmaMetni"
                id="aydinlatmaMeni"
              />
              <label class="form-check-label" for="aydinlatmaMeni">
                <a v-on:click="aydinlatmaMetni = true">{{
                  $t("setup.aydinlatma")
                }}</a
                >{{ $t("setup.read") }}
              </label>
            </div>
            <div
              class="form-check"
              :class="[error && !isAcikRizaMetni ? 'border-red' : '']"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="isAcikRizaMetni"
                id="acikRizaMetni"
              />
              <label class="form-check-label" for="acikRizaMetni">
                <a v-on:click="acikRizaMetni = true">{{
                  $t("setup.acikriza")
                }}</a
                >{{ $t("setup.read") }}
              </label>
            </div>
          </div>
          <!-- Create Profile End -->
          <!-- Verify Create -->
          <div v-if="codeSent">
            <div class="form-group mt-2">
              {{ $t("setup.code") }}
              <input
                class="codeInput form-control"
                type="tel"
                placeholder="123456"
                maxlength="6"
                v-model="newUser.code"
                pattern="[0-9]*"
                inputmode="numeric"
                v-mask="'######'"
                autocomplete="one-time-code"
              />
              <div class="invalid-feedback">{{ $t("setup.required") }}</div>
            </div>
          </div>
          <!-- Verify Create -->

          <button
            class="setup-btn negative-button"
            @click="!codeSent ? createUser() : assignUser()"
          >
            {{ $t("setup.create") }}
          </button>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>
<script>
/* eslint-disable */
import BgProfile from "@/components/setup/BgProfile.vue";
import { HTTP } from "@/main-source";
import router from "@/router";
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
// import modal from "@/components/modal";
import Bottom from "@/components/Bottom.vue";
import { mask } from "vue-the-mask";
import Modal from "@/components/Contract-Modal.vue";

export default {
  directives: { mask },
  name: "Confirm",
  data() {
    return {
      cities: [],
      image: [],
      selectedCity: null,
      phone: null,
      shortlink: this.$route.params.shortlink,
      currentUser: null,
      newUser: {
        name: null,
        email: null,
        tel: null,
        password: null,
        additionalInfos: [
          {
            id: 2,
            type: "Email",
            label: "Email",
            value: null,
            isActive: false,
          },
        ],
        code: null,
      },
      newPassword: null,
      newFirm: null,
      selectedRole: null,
      codeSent: false,
      acikRizaMetni: false,
      aydinlatmaMetni: false,
      isAydinlatmaMetni: false,
      isAcikRizaMetni: false,
      error: false,
      passInput: "password",
    };
  },
  validations: {
    newUser: {
      name: { required },
      email: { required, email },
      tel: { required },
      password: { required },
    },
  },
  components: {
    BgProfile,
    Bottom,
    Modal,
  },
  created() {
    this.getData();
  },
  methods: {
      showPass() {
      if (this.passInput == "password") {
        this.passInput = "text";
      } else {
        this.passInput = "password";
      }
    },
    getData() {
      HTTP.get("/users/profile/" + this.shortlink)
        .then((result) => {
          this.currentUser = result.data;
          this.phone = result.data.tel;
          if (this.currentUser.isVerified) {
            router.push({
              name: "Edit",
              params: {
                shortlink: this.shortlink,
              },
            });
          }
          //   this.setJSONDataV2(result);
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Getting error while process.",
            showConfirmButton: false,
            timer: 1000,
          });
        });
    },
    setJSONDataV2(result) {
      // Set stringfied json

      if (result.data.highlights) {
        this.newUser.highlights = JSON.parse(result.data.highlights);
      } else {
        this.newUser.highlights = [];
      }
      // Set PDF Data
      if (result.data.pdfButton) {
        this.newUser.pdfButton = JSON.parse(result.data.pdfButton);
      } else {
        this.newUser.pdfButton = {
          buttonText: "Broşürümüzü İnceleyin",
          link: null,
        };
      }
      // Set socialAccounts
      if (result.data.socialAccounts) {
        this.newUser.socialAccounts = JSON.parse(result.data.socialAccounts);
      } else {
        this.newUser.socialAccounts = [];
      }
      // Set Additional Infos
      if (result.data.additionalInfos) {
        this.newUser.additionalInfos = JSON.parse(result.data.additionalInfos);
      } else {
        this.newUser.additionalInfos = [];
      }
      // Set Address
      if (result.data.addresses) {
        this.newUser.addresses = JSON.parse(result.data.addresses);
      } else {
        this.newUser.addresses = [];
      }
      // Set banking info as a billing
      if (result.data.billingInfos) {
        this.newUser.billingInfos = JSON.parse(result.data.billingInfos);
      } else {
        this.newUser.billingInfos = [];
      }
    },
    setPhone(val) {
      if (val.isValid == true) {
        this.newUser.countryCode = val.countryCode;
        if (this.newUser.countryCode != "TR") {
          this.newUser.tel = val.formattedNumber;
        } else {
          this.newUser.tel = val.nationalNumber;
        }
      } else {
        this.newUser.countryCode = null;
        this.newUser.tel = null;
      }
    },
    passwordToggle: function () {
      this.passInputType == "password"
        ? (this.passInputType = "text")
        : (this.passInputType = "password");
    },
    createUser() {
      this.$v.newUser.$touch();
      if (
        !this.$v.newUser.$invalid &&
        this.isAydinlatmaMetni & this.isAcikRizaMetni
      ) {
        this.newUser.shortlink = this.shortlink;

        if (!this.newUser.additionalInfos[0].value) {
          delete this.newUser.additionalInfos;
        }

        HTTP.post("/users/verify-number", this.newUser)
          .then((result) => {
            this.codeSent = true;
          })
          .catch((e) => {
            if (e.response.status == 409) {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "This email or mobile phone already exists",
                showConfirmButton: true,
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Getting error while process.",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      } else {
        this.error = true;
      }
    },
    assignUser() {
      console.log(this.newUser);

      this.newUser.shortlink = this.shortlink;
      HTTP.post("/users/assign-user", this.newUser)
        .then((result) => {
          localStorage.setItem("user", JSON.stringify(result.data));
          HTTP.defaults.headers.common.Authorization =
            "Bearer " + result.data.token;
          router.push({
            name: "Edit",
            params: {
              shortlink: result.data.shortlink,
            },
          });
        })
        .catch((e) => {
          if (e.response.status == 409) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "This email or mobile phone already exists",
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Getting error while process.",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
    changePassword() {
      this.$v.newPassword.$touch();
      if (this.$v.newPassword.$invalid) {
        return;
      } else {
        let data = {
          userId: this.newUser.id,
          password: this.newPassword,
        };

        HTTP.patch("users/changepassword", data)
          .then(() => {
            this.changePasswordModal = false;
            this.$v.$reset();
            Swal.fire({
              icon: "success",
              text: "Successfully Updated.",
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "warning",
              text: "Please check form",
            });
          });
      }
    },

    checkFileSize(file) {
      let maxSize = this.$maxFileSize;
      if (file.size > maxSize) {
        Swal.fire({
          icon: "warning",
          text:
            "File size is too big. Max file size is " +
            Math.floor(maxSize / 1000000) +
            "MB",
        });
        return false;
      }
      return true;
    },
  },
};
</script>
