<template>
  <div class="box-area">
    <div class="container">
      <Modal v-if="acikRizaMetni" @close="acikRizaMetni = false">
        <div slot="title"><b>{{ $t("setup.acikriza") }}</b></div>
        <template slot="body">
          <iframe
            src="https://quicktouch.com.tr/acik-riza-metni/"
            frameborder="0"
          ></iframe>
        </template>
        <div slot="footer"></div>
      </Modal>
      <Modal v-if="aydinlatmaMetni" @close="aydinlatmaMetni = false">
        <div slot="title"><b>{{ $t("setup.aydinlatma") }}</b></div>
        <template slot="body">
          <iframe
            src="https://quicktouch.com.tr/aydinlatma-metni/"
            frameborder="0"
          ></iframe>
        </template>
        <div slot="footer"></div>
      </Modal>
      <div class="verify-area">
        <span class="s-heading">{{ $t("setup.setquick") }}</span>
        <span class="s-desc">{{ $t("setup.verifynum") }}</span>

        <div v-if="error" class="notice" role="alert">
          <span v-if="!telisvalid && error" class="s-desc"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-exclamation-triangle"
            >
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
              ></path>
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
              ></path></svg
            >{{ $t("setup.checktel") }}<br
          /></span>
          <!-- <span v-if="kvkk != true" class="s-desc"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-exclamation-triangle"
            >
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
              ></path>
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
              ></path></svg
            >Please accept terms</span
          > -->
        </div>

        <vue-phone-number-input
          default-country-code="TR"
          v-model="tel"
          @update="updatePhoneNumber"
        ></vue-phone-number-input>
        <div
          class="form-check"
          :class="[error && !isAydinlatmaMetni ? 'border-red' : '']"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="isAydinlatmaMetni"
            id="aydinlatmaMeni"
          />
          <label class="form-check-label" for="aydinlatmaMeni">
            <a v-on:click="aydinlatmaMetni = true">{{ $t("setup.aydinlatma") }}</a>{{ $t("setup.read") }}
          </label>
        </div>
        <div
          class="form-check"
          :class="[error && !isAcikRizaMetni ? 'border-red' : '']"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="isAcikRizaMetni"
            id="acikRizaMetni"
          />
          <label class="form-check-label" for="acikRizaMetni">
            <a v-on:click="acikRizaMetni = true">{{ $t("setup.acikriza") }}</a>{{ $t("setup.read") }}
          </label>
        </div>
        <btn-setup
          :btnText="$i18n.locale == 'tr' ? 'Hadi Başlayalım' : 'Let\'s Start'"
          class="negative-button"
          @click.native="verifyPhone"
        ></btn-setup>
      </div>
    </div>
  </div>
</template>

<script>
import BtnSetup from "@/components/setup/BtnSetup.vue";
import Modal from "@/components/Contract-Modal.vue";

import { HTTP } from "@/main-source";
import router from "@/router";

export default {
  data() {
    return {
      userData: null,
      tel: null,
      validtel: null,
      shortlink: this.$route.params.shortlink || null,
      telinf: null,
      kvkk: false,
      error: false,
      telisvalid: false,
      acikRizaMetni: false,
      aydinlatmaMetni: false,
      isAydinlatmaMetni: false,
      isAcikRizaMetni: false,
    };
  },

  name: "VerifyArea",
  components: {
    BtnSetup,
    Modal,
  },
  created() {
    // Check shortlink and if is not redirect to home
    const url = "/users/profile/" + this.shortlink;

    HTTP.get(url)
      .then((result) => {
        this.userData = result.data;
      })
      .then(() => {
        if (this.userData.isVerified) {
          router.push({
            name: "Home",
            params: {
              shortlink: this.shortlink,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    updatePhoneNumber(data) {
      if (data.isValid) {
        console.log(data.nationalNumber);
        this.validtel = data.nationalNumber;
        this.telisvalid = data.isValid;
      }
    },
    verifyPhone() {
      if (
        this.validtel != null &&
        this.isAydinlatmaMetni != false &&
        this.isAcikRizaMetni != false
      ) {
        let data = {
          tel: this.validtel,
          shortlink: this.shortlink,
        };

        HTTP.post("/users/start", data)
          .then((result) => {
            if (result.status == 200) {
              router.push({
                name: "Confirm",
                params: {
                  shortlink: this.shortlink,
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = true;
            this.validtel = null;
          });
      } else {
        this.error = true;
      }
    },
  },
};
</script>
