<template>
  <div class="bg">
    <BgSetup />
    <div class="box-area">
      <div class="container">
        <div class="verify-area secure-form">
          <span class="s-heading">{{ $t("setup.secure") }}</span>

          <div class="form-group mt-2">
            <label>{{ $t("setup.pass") }}</label>
            <div class="d-flex mt-2">
            <input
              :type="passInput"
              class="form-control"
              :placeholder="$t('setup.pass')"
              v-model="newUser.password"
              autocomplete="new-password"
              @blur="$v.newUser.password.$touch()"
              :class="{
                'is-invalid': $v.newUser.password.$error,
              }"
            />
            <button v-on:click="showPass" class="show-pass-btn">
              <svg
                v-if="passInput == 'password'"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-eye"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                />
                <path
                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                />
              </svg>
              <svg
                v-if="passInput == 'text'"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-eye-slash"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                />
                <path
                  d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                />
                <path
                  d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                />
              </svg>
            </button>
             </div>
            <div class="invalid-feedback">{{ $t("setup.required") }}</div>
          </div>
          <div class="form-group mt-2">
            <label>{{ $t("setup.secmail") }}</label>
            <input
              type="email"
              class="form-control mt-2"
              placeholder="Kurtarma email adresi"
              v-model="backupEmail"
              autocomplete="new-password"
              @blur="$v.backupEmail.$touch()"
              :class="{
                'is-invalid': $v.backupEmail.$error,
              }"
            />
            <div class="invalid-feedback">{{ $t("setup.req") }}</div>
          </div>

          <btn-setup
            :btnText="$i18n.locale == 'tr' ? 'Hadi Başlayalım' : 'Let\'s Start'"
            class="negative-button"
            @click.native="letsStart"
          ></btn-setup>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
import BtnSetup from "@/components/setup/BtnSetup.vue";
import BgSetup from "@/components/setup/BgSetup.vue";
import Bottom from "@/components/Bottom.vue";
import { required, email } from "vuelidate/lib/validators";
import { HTTP } from "@/main-source";
import router from "@/router";
import Swal from "sweetalert2";

export default {
  name: "Secure",
  components: {
    BtnSetup,
    Bottom,
    BgSetup,
  },
  data() {
    return {
      userData: null,
      tel: null,
      validtel: null,
      shortlink: this.$route.params.shortlink || null,
      telinf: null,
      kvkk: false,
      error: false,
      telisvalid: false,
      newTermsModal: false,
      newKvkkModal: false,
      passInput: "password",

      newUser: {
        userId: null,
        password: null,
        additionalInfos: [
          {
            type: 2,
            value: null,
            label: "Email",
            isActive: false,
          },
        ],
      },
      backupEmail: null,
    };
  },
  validations: {
    newUser: {
      password: { required },
    },
    backupEmail: { email },
  },
  created() {
    const url = "/users/profile/" + this.$route.params.shortlink;

    HTTP.get(url)
      .then((result) => {
        this.newUser.userId = result.data.id;
      })
      .then(() => {
        if (this.newUser.isPasswordSetted) {
          router.push({
            name: "Edit",
            params: {
              shortlink: this.shortlink,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    showPass() {
      if (this.passInput == "password") {
        this.passInput = "text";
      } else {
        this.passInput = "password";
      }
    },
    letsStart() {
      this.$v.newUser.$touch();
      this.$v.backupEmail.$touch();
      if (this.$v.newUser.$invalid && this.$v.backupEmail.$invalid) {
        return;
      } else {
        this.newUser.additionalInfos[0].value = this.backupEmail;
        HTTP.patch("users/update-password", this.newUser)
          .then(() => {
            this.changePasswordModal = false;
            this.$v.$reset();
            router.push({
              name: "Edit",
              params: {
                shortlink: this.$route.params.shortlink,
              },
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "warning",
              text: "Please check form",
            });
          });
      }
    },
  },
};
</script>
