<template>
  <div>
    <BgArea />

    <div
      class="spinner-border text-warning"
      role="status"
      v-if="xhrRequest"
    ></div>
    <div v-if="userData">
      <ProfilPic
        :profileImg="userData.profileImg"
        :promotionalVideo="userData.video_url"
      />
      <Headings
        :name="userData.name"
        :title="userData.title"
        :tel="userData.tel"
        :email="userData.email"
        :web="userData.web"
        :countryCode="userData.countryCode"
        :userData="userData"
      />
      <More :highlights="userData.highlights" v-if="isPremium()" />

      <div class="user-info">
        <div class="container container-desktop">
          <PdfArea
            :pdfButton="userData.pdfButton"
            :promotionalVideo="userData.promotionalVideo"
            v-if="isPremium()"
          />
          <SocialArea :socialAccounts="userData.socialAccounts" />
          <Other :additionalInfos="userData.additionalInfos" />
          <Adress :addresses="userData.addresses" />
          <Payment :billingInfos="userData.billingInfos" />
          <div class="col text-center">
            <button class="share-button" @click="shareLink">
              {{ $t("profile.share") }}
            </button>
          </div>
          <div class="col text-center login-col mb-4">
            <a class="login-btn" :href="'/login'">{{ $t("profile.login") }}</a>
            <br /><a
              :href="shortlink + '/qr'"
              target="_blank"
              class="login-btn qr-code-button"
            >
              {{ $t("profile.qr") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
// @ is an alias to /src
import BgArea from "@/components/BgArea.vue";

import ProfilPic from "@/components/ProfilPic.vue";
import Headings from "@/components/Headings.vue";
import More from "@/components/More.vue";
import Bottom from "@/components/Bottom.vue";
import PdfArea from "@/components/PdfArea.vue";
import SocialArea from "@/components/SocialArea.vue";
import Other from "@/components/Other.vue";
import Adress from "@/components/Adress.vue";
import Payment from "@/components/Payment.vue";

import { HTTP } from "@/main-source";
import router from "@/router";
import store from "@/store/index.js";

export default {
  name: "Home",
  store: store,
  components: {
    BgArea,
    ProfilPic,
    Headings,
    More,
    Bottom,
    PdfArea,
    SocialArea,
    Other,
    Adress,
    Payment,
  },
  data() {
    return {
      shortlink: this.$route.params.shortlink || null,
      userData: null,
      xhrRequest: true,
    };
  },
  computed: {
    webShareApiSupported() {
      return navigator.share;
    },
  },
  created() {
    // Check shortlink and if is not redirect to home
    if (this.shortlink == null) {
      window.location = this.$webURL;
    }

    const url = "/users/profile/" + this.shortlink;

    HTTP.get(url)
      .then((result) => {
        this.userData = result.data;
        this.$i18n.locale = result.data.profileLang
          ? result.data.profileLang.toLowerCase()
          : "tr";
        console.log(this.userData);
        this.xhrRequest = false;
      })
      .then(() => {
        if (!this.userData.tel && !this.userData.email) {
          router.push({
            name: "Setup",
            params: {
              shortlink: this.shortlink,
            },
          });
        } else {
          if (!this.userData.isVerified) {
            router.push({
              name: "Verify",
              params: {
                shortlink: this.shortlink,
              },
            });
          }
        }
        // Count Pageview view
        if (
          this.userData.tel &&
          this.userData.email &&
          this.userData.isVerified
        ) {
          let savedUsers =
            JSON.parse(localStorage.getItem("viewedUsers")) || [];
          if (!savedUsers.includes(this.userData.id)) {
            HTTP.post("/views/" + this.userData.shortlink, {
              userId: this.userData.id,
            }).then(() => {
              savedUsers.push(this.userData.id);
              localStorage.setItem("viewedUsers", JSON.stringify(savedUsers));
            });
          }
        }
        // Count Pageview view
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    async shareLink() {
      if (navigator.share) {
        try {
          const shareData = {
            title: "Quicktouch",
            text: this.userData.name,
            url: this.$appURL + this.userData.shortlink,
          };
          await navigator.share(shareData);
          console.log("Share successfull");
        } catch (err) {
          console.log("Error: ", err);
        }
      } else {
        console.warn("Native Web Sharing not supported");
      }
    },
    isPremium() {
      let status = false;

      let todayDate = new Date().toISOString().slice(0, 10);
      todayDate = new Date(todayDate);

      let subsDate = new Date(this.userData.subscriptionEndDate);

      if (this.userData.subscriptionEndDate) {
        status = subsDate > todayDate ? true : false;
      }
      return status;
    },
  },
};
</script>
