<template>
  <div class="pp-area">
    <div class="pp-container" :style="'background:url(' + setBg() + ')'">
      <!-- <img v-if="profileImg" class="pp" :src="$filesURL + profileImg" alt="" />
      <img
        v-if="!profileImg"
        class="pp"
        src="@/assets/images/blank.png"
        alt=""
      /> -->

      <Video :promotionalVideo="promotionalVideo" />
    </div>
  </div>
</template>

<script>
import Video from "./VideoBtn.vue";

export default {
  name: "profilepic",
  props: ["profileImg", "promotionalVideo"],
  components: {
    Video,
  },
  methods: {
    setBg() {
      let img = "/blank.png";
      if (this.profileImg) {
        img = this.$filesURL + this.profileImg;
      }
      return img;
    },
  },
};
</script>
