<template>
  <div class="bg">
    <BgSetup />
    <div class="box-area">
      <div class="container">
        <div class="verify-area secure-form qr-code-area">
          <qrcode-vue :value="value" :size="size" level="H" v-if="value" />
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
import BgSetup from "@/components/setup/BgSetup.vue";
import Bottom from "@/components/Bottom.vue";

export default {
  name: "Secure",
  components: {
    Bottom,
    BgSetup,
  },
  data() {
    return {
      value: null,
      size: 170,
    };
  },

  created() {
    this.value = this.$appURL + this.$route.params.shortlink;
  },
  methods: {},
};
</script>
