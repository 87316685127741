<template>
  <div class="bg">
    <BgProfile />
    <div class="setup-container">
      <!-- Change Password Modal -->
      <Modal
        v-if="changePasswordModal"
        @close="changePasswordModal = false"
        class="bigger-modal"
      >
        <div slot="title">{{ $t("setup.changepassword") }}</div>
        <div slot="body">
          <div>
            <div class="form-group mt-2">
              <input
                type="password"
                class="form-control"
                :placeholder="
                  $i18n.locale == 'tr' ? 'Yeni Şifre' : 'New Password'
                "
                v-model="newPassword"
                autocomplete="new-password"
                @blur="$v.newPassword.$touch()"
                :class="{
                  'is-invalid': $v.newPassword.$error,
                }"
              />
              <div class="invalid-feedback">{{ $t("setup.required") }}</div>
            </div>
          </div>
        </div>
        <div slot="footer">
          <button
            type="button"
            class="delete-btn"
            @click="changePasswordModal = false"
          >
            {{ $t("setup.cancel") }}
          </button>
          <!-- class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4" -->
          <button
            type="button"
            class="delete-btn delete-btn-two"
            @click="changePassword"
          >
            {{ $t("setup.submit") }}
          </button>
        </div>
      </Modal>
      <!-- Change Password Modal END -->

      <Modal v-if="UserDeleteModal" @close="UserDeleteModal = false">
        <div slot="title">
          <b>{{ $t("setup.accdelete") }}</b>
        </div>
        <div slot="body">
          <p class="s-desc">
            {{ $t("setup.accdeletedesc") }}<br />
            {{ $t("setup.accdeletedesctwo") }}
          </p>
        </div>
        <div slot="footer">
          <button class="delete-btn" @click="userDelete">
            {{ $t("setup.accdeletebtn") }}
          </button>
          <button
            class="delete-btn delete-btn-two"
            @click="UserDeleteModal = false"
          >
            {{ $t("setup.notsure") }}
          </button>
        </div>
      </Modal>
      <Modal v-if="UserRecoveryModal" @close="UserRecoveryModal = false">
        <div slot="title">
          <b>{{ $t("setup.accreco") }}</b>
        </div>
        <div slot="body">
          <p class="s-desc">{{ $t("setup.accrecodesc") }}<br /></p>
        </div>
        <div slot="footer">
          <button class="delete-btn" @click="userRecover">
            {{ $t("setup.recover") }}
          </button>
          <button
            class="delete-btn delete-btn-two"
            @click="UserRecoveryModal = false"
          >
            {{ $t("setup.notsure") }}
          </button>
        </div>
      </Modal>
      <div class="container">
        <div class="setup-area edit-area">
          <span class="s-heading">{{ $t("setup.setprofile") }}</span>
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="card-body">
                <div class="met-profile">
                  <div class="met-profile-main">
                    <div class="met-profile-main-pic">
                      <file-upload
                        ref="upload"
                        class="file profile-img"
                        v-model="image"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        extensions="gif,jpg,jpeg,png"
                      >
                        <img
                          :src="
                            newUser.profileImg
                              ? $filesURL + newUser.profileImg
                              : require('@/assets/images/no-image.jpg')
                          "
                          v-if="!newProfileImageTemp"
                          alt="Profile Image"
                          class="rounded-circle c-size-prof"
                        />
                        <img
                          :src="newProfileImageTemp"
                          v-else
                          alt="Profile Image"
                          class="rounded-circle c-size-prof"
                        />
                        <span class="fro-profile_main-pic-change">
                          <i class="fas fa-camera"></i>
                        </span>
                      </file-upload>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group mt-2">
                      <label>{{ $t("setup.personelvideo") }}</label>

                      <div
                        class="
                          embed-responsive embed-responsive-16by9
                          video-wrapper
                          mb-2
                        "
                        v-if="newUser.video_url"
                      >
                        <button class="btn btn-danger btn-round btn-remove">
                          <i
                            class="fas fa-trash-alt"
                            @click="newUser.video_url = null"
                          ></i>
                        </button>
                        <video
                          width="320"
                          height="240"
                          controls
                          v-if="newUser.video_url"
                        >
                          <source
                            :src="$filesURL + newUser.video_url"
                            :type="setVideoTypeForVideoTag(newUser.video_url)"
                          />
                          {{ $t("profile.videoerr") }}
                        </video>
                      </div>

                      <div class="mb-3">
                        <input
                          class="form-control form-control-sm"
                          type="file"
                          id="formFileSm"
                          accept="video/mp4,video/x-m4v,video/*"
                          @change="personelVideoFileChange"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row meta-info">
                  <div class="col-12 fs-7 mt-2" v-if="newUser.firmId">
                    <strong>{{ $t("setup.firm") }}</strong>
                    <p>
                      {{ newUser.firm.name }}
                    </p>
                  </div>

                  <div class="col-12 fs-7 mt-2">
                    <strong>{{ $t("setup.language") }}:</strong>
                    <select
                      class="form-select form-control"
                      v-model="newUser.profileLang"
                      @change="ChangeLang"
                    >
                      <option
                        v-for="(option, key) in langOptions"
                        :value="option.value"
                        :key="key"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-9">
              <div class="card-body">
                <div class="form-group mt-2">
                  <label>{{ $t("setup.name") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$i18n.locale == 'tr' ? 'İsim' : 'Name'"
                    autocomplete="nope"
                    v-model="newUser.name"
                    @blur="$v.newUser.name.$touch()"
                    :class="{
                      'is-invalid': $v.newUser.name.$error,
                    }"
                  />
                  <div class="invalid-feedback">{{ $t("setup.required") }}</div>
                </div>
                <div class="form-group mt-2">
                  <label>{{ $t("setup.title") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$i18n.locale == 'tr' ? 'Başlık' : 'Title'"
                    autocomplete="nope"
                    v-model="newUser.title"
                  />
                </div>
                <div class="form-group mt-2">
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    autocomplete="nope"
                    v-model="newUser.email"
                    @blur="$v.newUser.email.$touch()"
                    :class="{
                      'is-invalid': $v.newUser.email.$error,
                    }"
                  />
                  <div class="invalid-feedback">{{ $t("setup.required") }}</div>
                </div>
                <div class="form-group mt-2">
                  <label>Web</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="web"
                    autocomplete="nope"
                    v-model="newUser.web"
                  />
                </div>
                <div class="form-group mt-2">
                  <label>Tel</label>
                  <vue-phone-number-input
                    v-model="phone"
                    @update="setPhone"
                    @blur="$v.newUser.tel.$touch()"
                    :show-code-on-list="true"
                    :default-country-code="newUser.countryCode"
                    :class="{
                      'is-invalid': $v.newUser.tel.$error,
                    }"
                  />

                  <div class="invalid-feedback">{{ $t("setup.required") }}</div>
                </div>

                <div class="row mt-4" v-if="isPremium()">
                  <hr />
                  <div class="col-12 col-sm-6">
                    <label>{{ $t("setup.promotinolvideo") }}</label>
                    <div class="row">
                      <div
                        class="
                          embed-responsive embed-responsive-16by9
                          video-wrapper row
                        "
                        v-if="newUser.promotionalVideo"
                      >
                        <button class="btn btn-danger btn-round btn-remove">
                          <i
                            class="fas fa-trash-alt"
                            @click="newUser.promotionalVideo = null"
                          ></i>
                        </button>
                        <video
                          width="320"
                          height="240"
                          controls
                          v-if="!checkIfYoutubeLink(newUser.promotionalVideo)"
                        >
                          <source
                            :src="$filesURL + newUser.promotionalVideo"
                            :type="
                              setVideoTypeForVideoTag(newUser.promotionalVideo)
                            "
                          />
                          {{ $t("profile.videoerr") }}
                        </video>

                        <iframe
                          width="560"
                          height="315"
                          v-if="checkIfYoutubeLink(newUser.promotionalVideo)"
                          :src="
                            returnYoutubeEmbedCode(newUser.promotionalVideo)
                          "
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                    <div class="video-manage mt-5">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            {{ $t("setup.videoupload") }}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            Youtube Link
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div class="form-group mt-2">
                            <div class="custom-file">
                              <div class="mb-3">
                                <input
                                  class="form-control form-control-sm"
                                  type="file"
                                  id="formFileSm"
                                  accept="video/mp4,video/x-m4v,video/mov"
                                  @change="promotionalVideoFileChange"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div class="form-group mt-2">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Youtube Link"
                              autocomplete="nope"
                              v-model="youtubeLink"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="form-group mt-2">
                      <label>{{ $t("setup.promotinolpdf") }}</label>
                      <div class="row" v-if="newUser.pdfButton.link">
                        <div class="col-10">
                          <a
                            :href="$filesURL + newUser.pdfButton.link"
                            target="_blank"
                            rel="nofollow"
                            class="btn waves-effect btn-block mb-2 s-o"
                            ><i class="fas fa-file-pdf"></i>
                            {{ $t("setup.pdfrev") }}
                          </a>
                        </div>
                        <div class="col-2">
                          <button
                            class="btn btn-danger remove-pdf-button"
                            @click="removePDFData"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>

                      <div class="custom-file mt-2">
                        <input
                          class="form-control form-control-sm"
                          type="file"
                          id="formFileSm"
                          accept=".pdf"
                          @change="promotionalPDFFileChange"
                        />
                      </div>
                    </div>
                    <div class="form-group mt-2" v-if="newUser.pdfButton">
                      <label> {{ $t("setup.pdfbtn") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="newUser.pdfButton.buttonText"
                        autocomplete="nope"
                        v-model="newUser.pdfButton.buttonText"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="isPremium()">
                  <div class="col-12 col-sm-12">
                    <div class="form-group mt-2 highlights">
                      <label>{{ $t("profile.highlights") }}</label>
                      <div v-if="newUser.highlights.length == 0">
                        <i
                          class="
                            mdi mdi-information
                            text-primary
                            me-1
                            align-middle
                            font-size-16
                          "
                        ></i>
                        {{ $t("setup.highlightstext") }}
                      </div>
                      <draggable
                        handle=".handle"
                        class="list-group"
                        tag="ul"
                        v-model="newUser.highlights"
                        v-bind="dragOptions"
                      >
                        <!-- draggable=".item" -->
                        <transition-group type="transition">
                          <li
                            class="list-group-item mt-3"
                            v-for="(highlight, index) in newUser.highlights"
                            :key="index"
                            :class="[
                              newHighLightImage(index) || !highlight.image
                                ? 'new-item'
                                : 'item',
                            ]"
                          >
                            <i
                              class="fas fa-trash-alt me-2 positioned-right"
                              @click="removeItem('highlight', index)"
                            ></i>
                            <div class="form-switch positioned-right">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                v-model="highlight.isActive"
                              />
                            </div>
                            <i
                              class="fa fa-align-justify handle"
                              aria-hidden="true"
                            ></i>
                            <img
                              :src="$filesURL + highlight.image"
                              v-if="highlight.image"
                              class="highlight-img ml-2"
                              alt=""
                            />

                            <div class="col-12 form-group d-inline-block mt-4">
                              <div
                                class="custom-file mb-2"
                                v-if="!highlight.image"
                              >
                                <input
                                  class="form-control form-control-sm"
                                  type="file"
                                  id="formFileSm"
                                  accept="image/png, image/jpeg, image/jpg"
                                  @change="function(file){highlightFileChange(file, index);}
                                  "
                                />
                              </div>
                              <label>{{ $t("setup.highlightsimage") }}</label>

                              <input
                                type="text"
                                class="form-control"
                                placeholder="Link"
                                accept="image/png, image/jpeg, image/jpg"
                                autocomplete="nope"
                                v-model="highlight.link"
                              />
                            </div>
                          </li>
                        </transition-group>
                      </draggable>
                      <div class="col-12 text-center">
                        <button
                          class="btn btn-light btn-rounded add-new-info"
                          @click="addInfo('highlight')"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12">
                    <div class="form-group mt-2">
                      <label>{{ $t("setup.social") }}</label>
                      <div v-if="newUser.socialAccounts.length == 0">
                        <i
                          class="
                            mdi mdi-information
                            text-primary
                            me-1
                            align-middle
                            font-size-16
                          "
                        ></i>
                        {{ $t("setup.highlightstext") }}
                      </div>
                      <draggable
                        handle=".handle"
                        class="list-group"
                        tag="ul"
                        v-model="newUser.socialAccounts"
                        v-bind="dragOptions"
                      >
                        <transition-group type="transition">
                          <li
                            class="list-group-item mt-3"
                            v-for="(
                              socialAccount, index
                            ) in newUser.socialAccounts"
                            :key="index"
                          >
                            <i
                              class="fa fa-align-justify handle"
                              aria-hidden="true"
                            ></i>
                            <div class="form-switch positioned-right">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                v-model="socialAccount.isActive"
                              />
                            </div>
                            <i
                              class="fas fa-trash-alt me-2 positioned-right"
                              @click="removeItem('social', index)"
                            ></i>
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <label> {{ $t("setup.type") }} </label>
                                  <v-select
                                    class="mt-1"
                                    v-model="socialAccount.type"
                                    :options="$socialMediaList"
                                    label="label"
                                    :placeholder="
                                      $i18n.locale == 'tr'
                                        ? 'Lütfen sosyal medya platformunu seçin'
                                        : 'Please select social media platform'
                                    "
                                  >
                                    <template slot="option" slot-scope="option">
                                      <!-- HTML that describe how select should render items when the select is open -->
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug === 'Facebook'"
                                        src="@/assets/images/fb.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug === 'Instagram'"
                                        src="@/assets/images/inst.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Twitter'"
                                        src="@/assets/images/tw.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Pinterest'"
                                        src="@/assets/images/pin.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'YouTube'"
                                        src="@/assets/images/yt.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Reddit'"
                                        src="@/assets/images/reddit.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'WeChat'"
                                        src="@/assets/images/wechat.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'LinkedIn'"
                                        src="@/assets/images/linkedin.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'TikTok'"
                                        src="@/assets/images/tiktok.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'QQ'"
                                        src="@/assets/images/qq.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Tumblr'"
                                        src="@/assets/images/tumblr.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Qzone'"
                                        src="@/assets/images/qzone.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Baidu Tieba'"
                                        src="@/assets/images/baidu.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Viber'"
                                        src="@/assets/images/viber.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Snapchat'"
                                        src="@/assets/images/snapchat.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Line'"
                                        src="@/assets/images/line.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Telegram'"
                                        src="@/assets/images/telegram.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Medium'"
                                        src="@/assets/images/medium.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Vimeo'"
                                        src="@/assets/images/vimeo.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Behance'"
                                        src="@/assets/images/behance.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == '500px'"
                                        src="@/assets/images/500px.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Blogger'"
                                        src="@/assets/images/blogger.svg"
                                      />
                                      <img
                                        class="slug-img-option"
                                        v-if="option.slug == 'Flicker'"
                                        src="@/assets/images/flick.svg"
                                      />
                                      {{ option.slug }}
                                    </template>
                                  </v-select>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group">
                                  <label>Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                      $i18n.locale == 'tr' ? 'İsim' : 'Name'
                                    "
                                    autocomplete="nope"
                                    v-model="socialAccount.link"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </transition-group>
                      </draggable>
                      <div class="col-12 text-center">
                        <button
                          class="btn btn-light btn-rounded add-new-info"
                          @click="addInfo('social')"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label>{{ $t("setup.additional") }}</label>
                  <div v-if="newUser.additionalInfos.length == 0">
                    <i
                      class="
                        mdi mdi-information
                        text-primary
                        me-1
                        align-middle
                        font-size-16
                      "
                    ></i>
                    {{ $t("setup.highlightstext") }}
                  </div>
                  <draggable
                    handle=".handle"
                    class="list-group"
                    tag="ul"
                    v-model="newUser.additionalInfos"
                    v-bind="dragOptions"
                  >
                    <transition-group type="transition">
                      <li
                        class="list-group-item mt-3"
                        v-for="(info, index) in newUser.additionalInfos"
                        :key="index"
                      >
                        <i
                          class="fa fa-align-justify handle"
                          aria-hidden="true"
                        ></i>
                        <div class="form-switch positioned-right">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            v-model="info.isActive"
                          />
                        </div>
                        <i
                          class="fas fa-trash-alt me-2 positioned-right"
                          @click="removeItem('additional', index)"
                        ></i>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label>{{ $t("setup.type") }}</label>
                              <v-select
                                class="mt-1"
                                v-model="info.type"
                                :options="additionalInfoTypes"
                                label="label"
                                :placeholder="
                                  $i18n.locale == 'tr'
                                    ? 'Lütfen bilgi tipi seçin'
                                    : 'Please select type'
                                "
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label>{{ $t("setup.value") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="
                                  $i18n.locale == 'tr' ? 'İsim' : 'Name'
                                "
                                autocomplete="nope"
                                v-model="info.value"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    </transition-group>
                  </draggable>
                  <div class="col-12 text-center">
                    <button
                      class="btn btn-light btn-rounded add-new-info"
                      @click="addInfo('additional')"
                    >
                      +
                    </button>
                  </div>
                </div>

                <div class="form-group mt-2">
                  <label>{{ $t("setup.adresses") }}</label>
                  <div v-if="newUser.addresses.length == 0">
                    <i
                      class="
                        mdi mdi-information
                        text-primary
                        me-1
                        align-middle
                        font-size-16
                      "
                    ></i>
                    {{ $t("setup.highlightstext") }}
                  </div>
                  <draggable
                    handle=".handle"
                    class="list-group"
                    tag="ul"
                    v-model="newUser.addresses"
                    v-bind="dragOptions"
                  >
                    <transition-group type="transition">
                      <li
                        class="list-group-item mt-3"
                        v-for="(address, index) in newUser.addresses"
                        :key="index"
                      >
                        <i
                          class="fa fa-align-justify handle"
                          aria-hidden="true"
                        ></i>
                        <div class="form-switch positioned-right">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            v-model="address.isActive"
                          />
                        </div>
                        <i
                          class="fas fa-trash-alt me-2 positioned-right"
                          @click="removeItem('address', index)"
                        ></i>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label>{{ $t("setup.title") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="
                                  $i18n.locale == 'tr' ? 'Başlık' : 'Title'
                                "
                                autocomplete="nope"
                                v-model="address.title"
                              />
                            </div>
                            <div class="form-group">
                              <label>{{ $t("setup.adress") }}</label>
                              <textarea
                                class="form-control"
                                :placeholder="
                                  $i18n.locale == 'tr' ? 'Adres' : 'Adress'
                                "
                                autocomplete="nope"
                                v-model="address.desc"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    </transition-group>
                  </draggable>
                  <div class="col-12 text-center">
                    <button
                      class="btn btn-light btn-rounded add-new-info"
                      @click="addInfo('address')"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label>{{ $t("setup.bankinfos") }}</label>
                  <div v-if="newUser.billingInfos.length == 0">
                    <i
                      class="
                        mdi mdi-information
                        text-primary
                        align-middle
                        font-size-16
                      "
                    ></i>
                    {{ $t("setup.highlightstext") }}
                  </div>
                  <draggable
                    handle=".handle"
                    class="list-group"
                    tag="ul"
                    v-model="newUser.billingInfos"
                    v-bind="dragOptions"
                  >
                    <transition-group type="transition">
                      <li
                        class="list-group-item mt-3"
                        v-for="(bankInfo, index) in newUser.billingInfos"
                        :key="index"
                      >
                        <i
                          class="fa fa-align-justify handle"
                          aria-hidden="true"
                        ></i>
                        {{ bankInfo.title }}
                        <div class="form-switch positioned-right">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            v-model="bankInfo.isActive"
                          />
                        </div>
                        <i
                          class="fas fa-trash-alt me-2 positioned-right"
                          @click="removeItem('bank', index)"
                        ></i>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label>{{ $t("setup.title") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="
                                  $i18n.locale == 'tr' ? 'Başlık' : 'Title'
                                "
                                autocomplete="nope"
                                v-model="bankInfo.title"
                              />
                            </div>
                            <div class="form-group mb-3">
                              <label>{{ $t("profile.bank") }}</label>
                              <vue-simple-suggest
                                :list="bankList"
                                :filter-by-query="true"
                                v-model="bankInfo.bank"
                                class="bank-list-input"
                                :placeholder="
                                  $i18n.locale == 'tr'
                                    ? 'Banka Adı'
                                    : 'Bank Name'
                                "
                              >
                              </vue-simple-suggest>
                            </div>
                            <div class="form-group">
                              <label>{{ $t("setup.recipientname") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="
                                  $i18n.locale == 'tr'
                                    ? 'Hesap Sahibi'
                                    : 'Recipient Name'
                                "
                                autocomplete="nope"
                                v-model="bankInfo.recipientName"
                              />
                            </div>
                            <div class="form-group">
                              <label>IBAN</label>
                              <the-mask
                                mask="TR## #### #### #### #### #### #### #### ##"
                                class="form-control"
                                placeholder="IBAN"
                                autocomplete="nope"
                                v-model="bankInfo.iban"
                              ></the-mask>
                            </div>
                          </div>
                        </div>
                      </li>
                    </transition-group>
                  </draggable>
                  <div class="col-12 text-center">
                    <button
                      class="btn btn-light btn-rounded add-new-info"
                      @click="addInfo('bank')"
                    >
                      +
                    </button>
                  </div>
                </div>

                <div class="clearfix"></div>
              </div>
              <button class="setup-btn negative-button" @click="updateUser">
                <span v-if="!xhrUpdate"> {{ $t("setup.update") }} </span>
                <Loading :white="true" v-if="xhrUpdate"></Loading>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container edit-profile-bottom">
        <div class="row">
          <div class="col">
            <button @click="buyBackupCard">
              <i class="fas fa-external-link-alt"></i>
              {{ $t("setup.buybcard") }}
            </button>
          </div>
          <div class="col">
            <a :href="$appURL + newUser.shortlink" target="_blank">
              <i class="fas fa-address-card"></i>
              {{ $t("setup.vprofile") }}
            </a>
          </div>
          <div class="col">
            <button @click="changePasswordModal = true">
              <i class="fas fa-lock"></i>
              {{ $t("setup.changepassword") }}
            </button>
          </div>

          <div class="col">
            <button @click="logout">
              <i class="fas fa-sign-out-alt"></i>
              {{ $t("setup.logout") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <Bottom />
    <div class="row delete-field">
      <div class="col">
        <button v-if="!this.newUser.willBeDelete" @click="deleteUser">
          {{ $t("setup.accdelete") }}
        </button>

        <button v-if="this.newUser.willBeDelete" @click="recoveryUser">
          {{ $t("setup.accreco") }}
        </button>
      </div>
    </div>
    <form
      id="backupCardForm"
      method="post"
      class="d-none"
      :action="$webURL + 'user-holder'"
      target="backupCard"
    >
      <input type="text" name="user" />
    </form>
  </div>
</template>
<script>
import BgProfile from "@/components/setup/BgProfile.vue";
import { HTTP } from "@/main-source";
import router from "@/router";
import { required, minLength, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
// import modal from "@/components/modal";
import draggable from "vuedraggable";
import Bottom from "@/components/Bottom.vue";
import Modal from "@/components/Modal.vue";
import Loading from "@/components/Loading.vue";
import { TheMask } from "vue-the-mask";

export default {
  name: "Confirm",
  data() {
    return {
      cities: [],
      image: [],
      firms: [],
      roles: [],
      langOptions: [
        { value: "TR", text: "Türkçe" },
        { value: "EN", text: "English" },
      ],
      bankList: [
        "Türkiye Cumhuriyeti Ziraat Bankası A.Ş.",
        "Türkiye Halk Bankası A.Ş.",
        "Akbank T.A.Ş.",
        "Anadolubank A.Ş.",
        "Fibabanka A.Ş.",
        "Şekerbank T.A.Ş.",
        "Turkish Bank A.Ş.",
        "Türk Ekonomi Bankası A.Ş.",
        "Türkiye İş Bankası A.Ş.",
        "Yapı ve Kredi Bankası A.Ş.",
        "Adabank A.Ş.",
        "Birleşik Fon Bankası A.Ş.",
        "Türk Ticaret Bankası A.Ş.",
        "Alternatifbank A.Ş.",
        "Arap Türk Bankası A.Ş.",
        "Bank of China Turkey A.Ş.",
        "Burgan Bank A.Ş.",
        "Citibank A.Ş.",
        "Denizbank A.Ş.",
        "Deutsche Bank A.Ş.",
        "HSBC Bank A.Ş.",
        "ICBC Turkey Bank A.Ş.",
        "ING Bank A.Ş.",
        "MUFG Bank Turkey A.Ş.",
        "Odea Bank A.Ş.",
        "QNB Finansbank A.Ş.",
        "Rabobank A.Ş.",
        "Turkland Bank A.Ş.",
        "Türkiye Garanti Bankası A.Ş.",
        "Bank Mellat",
        "Habib Bank Limited",
        "Intesa Sanpaolo S.p.A.",
        "JPMorgan Chase Bank N.A.",
        "Société Générale (SA)",
        "İller Bankası A.Ş.",
        "Türk Eximbank",
        "Türkiye Kalkınma ve Yatırım Bankası A.Ş.",
        "Aktif Yatırım Bankası A.Ş.",
        "D Yatırım Bankası A.Ş.",
        "Destek Yatırım Bankası A.Ş.",
        "Diler Yatırım Bankası A.Ş.",
        "Golden Global Yatırım Bankası A.Ş.",
        "GSD Yatırım Bankası A.Ş.",
        "İstanbul Takas ve Saklama Bankası A.Ş.",
        "Nurol Yatırım Bankası A.Ş.",
        "Türkiye Sınai Kalkınma Bankası A.Ş.",
        "Bank of America Yatırım Bank A.Ş.",
        "BankPozitif Kredi ve Kalkınma Bankası A.Ş.",
        "Pasha Yatırım Bankası A.Ş.",
        "Standard Chartered Yatırım Bankası Türk A.Ş.",
      ],
      selectedCity: null,
      phone: null,
      shortlink: this.$route.params.shortlink,
      UserDeleteModal: false,
      UserRecoveryModal: false,
      newUser: {
        name: null,
        email: null,
        profileImg: null,
        tel: null,
        video_url: null,
        desc: null,
        shortlink: null,
        by: null,
        willBeDelete: null,
        isVerified: null,
        highlights: [],
        socialAccounts: [],
        additionalInfos: [],
        addresses: [],
        bilgginInfos: [],
        title: null,
      },
      additionalInfoTypes: [
        { id: 1, type: "Phone", label: "Phone" },
        { id: 2, type: "Email", label: "Email" },
        { id: 3, type: "Simple Link", label: "Simple Link" },
      ],
      newPassword: null,
      newFirm: null,
      selectedRole: null,
      // Modals
      deleteUserModal: false,
      changePasswordModal: false,
      assignFirmModal: false,
      assignRoleModal: false,
      dragOptions: {
        animation: 100,
        ghostClass: "ghost",
      },
      newProfileImageTemp: null,
      // Files
      profileImage: null,
      personelVideo: null,
      promotionalVideo: null,
      promotionalPDF: null,
      newHighlights: [],
      youtubeLink: null,
      xhrUpdate: false,
    };
  },
  validations: {
    newUser: {
      name: { required },
      email: { required, email },
      tel: { required },
    },
    newPassword: { required, minLength: minLength(4) },
  },
  components: {
    draggable,
    BgProfile,
    Bottom,
    Modal,
    Loading,
    TheMask,
  },
  created() {
    console.log(this.$authenticatedUser);

    if (this.$authenticatedUser) {
      if (this.$authenticatedUser.shortlink != this.$route.params.shortlink) {
        localStorage.removeItem("user");
        HTTP.defaults.headers.common.Authorization = "";
        router.push({ name: "Login" });
      } else {
        this.getData();
      }
    } else {
      this.getData();
    }
  },
  methods: {
    // isPremium() {
    //   let status = false;
    //   if (this.newUser.subscriptionEndDate) {
    //     status =
    //       new Date(this.newUser.subscriptionEndDate + " 23:59:59") > new Date()
    //         ? true
    //         : false;
    //   }
    //   return status;
    // },
      isPremium() {
      let status = false;

      let todayDate = new Date().toISOString().slice(0, 10);
      todayDate = new Date(todayDate);

      let subsDate = new Date(this.newUser.subscriptionEndDate);

      if (this.newUser.subscriptionEndDate) {
        status = subsDate > todayDate ? true : false;
      }
      return status;
    },

    /*eslint-disable */
    async buyBackupCard() {
      let userData = JSON.stringify(this.newUser);
      let formData = new FormData();
      formData.append("cards", userData);
      var f = document.getElementById("backupCardForm");
      f.user.value = userData;
      window.open("", "backupCard");
      f.submit();
    },
    recoveryUser() {
      this.UserRecoveryModal = "true";
    },
    ChangeLang() {
      this.$i18n.locale = this.newUser.profileLang
        ? this.newUser.profileLang.toLowerCase()
        : "tr";
    },
    logout: function () {
      localStorage.removeItem("user");
      HTTP.defaults.headers.common.Authorization = "";
      router.push("/" + this.shortlink);
    },
    deleteUser() {
      this.UserDeleteModal = true;
    },
    checkIfYoutubeLink(link) {
      if (link.includes("youtube")) {
        return true;
      }
      return false;
    },
    returnYoutubeEmbedCode(link) {
      if (link.includes("youtube")) {
        return "https://www.youtube.com/embed/" + link.split("v=")[1];
      }
    },
    setVideoTypeForVideoTag(videoName) {
      let ext = videoName.split(".")[1];
      return "video/mp4";
      // return "video/" + ext;
    },
    userDelete() {
      HTTP.patch("users/" + this.shortlink + "/delete").then((res) => {
        Swal.fire({
          title:
            this.$i18n.locale == "tr"
              ? "Silme Talebi Planlandı."
              : "Delete Request Planned!",
          text:
            this.$i18n.locale == "tr"
              ? "Hesabınız silinmek için planlandı."
              : "Your account has been planning to deleted.",
          icon: "success",
          confirmButtonText: "OK",
        });
        this.UserDeleteModal = false;
        location.reload();
      });
    },
    userRecover() {
      HTTP.patch("users/" + this.shortlink + "/delete/cancel").then((res) => {
        Swal.fire({
          title:
            this.$i18n.locale == "tr"
              ? "Kurtarma Talebi Planlandı"
              : "Recovery Request Planned!",
          text:
            this.$i18n.locale == "tr"
              ? "Hesabınız kurtarmayı planlıyor"
              : "Your account has been planning to recovery.",
          icon: "success",
          confirmButtonText: "OK",
        });
        this.UserRecoveryModal = false;
        location.reload();
      });
    },
    getData() {
      HTTP.get("/users/profile/" + this.shortlink)
        .then((result) => {
          this.newUser = result.data;
          this.phone = result.data.tel;
          this.setJSONDataV2(result);
          console.log(result.data);
          this.$i18n.locale = this.newUser.profileLang
            ? this.newUser.profileLang.toLowerCase()
            : "tr";

          // Get Roles and set values
          // HTTP.get("roles").then((result) => {
          //   if (result.data) {
          //     this.roles = result.data.map((role) => {
          //       if (this.newUser.firmId == null && role.id == 2) {
          //         return;
          //       } else {
          //         return { label: role.name, id: role.id };
          //       }
          //     });
          //   }
          // });
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: new.vue ~ line 216 ~ HTTP.get ~ error",
            error
          );
          Swal.fire({
            position: "center",
            icon: "error",
            title:
              this.$i18n.locale == "tr"
                ? "İşlem sırasında hata oluştu"
                : "Getting error while process",
            showConfirmButton: false,
            timer: 1000,
          });
        });
      // HTTP.get("firms").then((result) => {
      //   if (result.data.rows) {
      //     this.firms = result.data.rows.map((firm) => {
      //       return { label: firm.name, id: firm.id };
      //     });
      //   }
      // });
    },
    removePDFData: function () {
      this.newUser.pdfButton = {
        buttonText: "Broşürümüzü İnceleyin",
        link: null,
      };
    },
    setJSONDataV2(result) {
      // Set stringfied json

      if (result.data.highlights) {
        this.newUser.highlights = JSON.parse(result.data.highlights);
      } else {
        this.newUser.highlights = [];
      }
      // Set PDF Data
      if (result.data.pdfButton) {
        this.newUser.pdfButton = JSON.parse(result.data.pdfButton);
      } else {
        this.newUser.pdfButton = {
          buttonText: "Broşürümüzü İnceleyin",
          link: null,
        };
      }
      // Set socialAccounts
      if (result.data.socialAccounts) {
        this.newUser.socialAccounts = JSON.parse(result.data.socialAccounts);
      } else {
        this.newUser.socialAccounts = [];
      }
      // Set Additional Infos
      if (result.data.additionalInfos) {
        this.newUser.additionalInfos = JSON.parse(result.data.additionalInfos);
      } else {
        this.newUser.additionalInfos = [];
      }
      // Set Address
      if (result.data.addresses) {
        this.newUser.addresses = JSON.parse(result.data.addresses);
      } else {
        this.newUser.addresses = [];
      }
      // Set banking info as a billing
      if (result.data.billingInfos) {
        this.newUser.billingInfos = JSON.parse(result.data.billingInfos);
      } else {
        this.newUser.billingInfos = [];
      }
    },
    setPhone(val) {
      if (val.isValid == true) {
        this.newUser.countryCode = val.countryCode;
        if (this.newUser.countryCode != "TR") {
          this.newUser.tel = val.formattedNumber;
        } else {
          this.newUser.tel = val.nationalNumber;
        }
      } else {
        this.newUser.countryCode = null;
        this.newUser.tel = null;
      }
    },
    passwordToggle: function () {
      this.passInputType == "password"
        ? (this.passInputType = "text")
        : (this.passInputType = "password");
    },
    addInfo(what) {
      switch (what) {
        case "highlight":
          this.newUser.highlights.push({
            image: null,
            link: null,
            file: null,
            isActive: true,
          });
          break;

        case "additional":
          this.newUser.additionalInfos.push({
            type: null,
            value: null,
            isActive: true,
          });
          break;
        case "address":
          this.newUser.addresses.push({
            title: null,
            desc: null,
            mapFrame: null,
            isActive: true,
          });
          break;
        case "bank":
          this.newUser.billingInfos.push({
            title: null,
            bank: null,
            recipientName: null,
            iban: null,
            isActive: true,
          });
          break;
        case "social":
          this.newUser.socialAccounts.push({
            type: null,
            link: null,
            isActive: true,
          });
          break;
        default:
          break;
      }
    },
    removeItem(which, index) {
      switch (which) {
        case "highlight":
          this.newUser.highlights.splice(index, 1);
          break;
        case "additional":
          this.newUser.additionalInfos.splice(index, 1);
          break;
        case "address":
          this.newUser.addresses.splice(index, 1);
          break;
        case "bank":
          this.newUser.billingInfos.splice(index, 1);
          break;
        case "social":
          this.newUser.socialAccounts.splice(index, 1);
          break;
        default:
          break;
      }
    },
    makeRandom(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    async checkFilesAndUpload() {
      /* eslint-disable */
      try {
        if (this.profileImage) {
          let formData = new FormData();
          formData.append("files", this.profileImage.file);
          await HTTP.post("/files/images", formData, {
            headers: { "Content-Type": "multipart/form-data;" },
          })
            .then((result) => {
              this.newUser.profileImg = result.data.data;
            })
            .catch((error) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  this.$i18n.locale == "tr"
                    ? "İşlem sırasında hata oluştu"
                    : "Getting error while process",
                showConfirmButton: false,
                timer: 1000,
              });
            });
        }
        if (this.personelVideo) {
          let formData = new FormData();
          formData.append("files", this.personelVideo);
          await HTTP.post("/files", formData, {
            headers: { "Content-Type": "multipart/form-data;" },
          })
            .then((result) => {
              this.newUser.video_url = result.data.data[0].filename;
            })
            .catch((error) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  this.$i18n.locale == "tr"
                    ? "İşlem sırasında hata oluştu"
                    : "Getting error while process",
                showConfirmButton: false,
                timer: 1000,
              });
            });
        }

        // Set promotional Video URL
        if (this.promotionalVideo) {
          this.youtubeLink = null;
          let formData = new FormData();
          formData.append("files", this.promotionalVideo);
          await HTTP.post("/files", formData, {
            headers: { "Content-Type": "multipart/form-data;" },
          })
            .then((result) => {
              this.newUser.promotionalVideo = result.data.data[0].filename;
            })
            .catch((error) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  this.$i18n.locale == "tr"
                    ? "İşlem sırasında hata oluştu"
                    : "Getting error while process",
                showConfirmButton: false,
                timer: 1000,
              });
            });
        }

        if (this.promotionalPDF) {
          let formData = new FormData();
          formData.append("files", this.promotionalPDF);
          await HTTP.post("/files", formData, {
            headers: { "Content-Type": "multipart/form-data;" },
          })
            .then((result) => {
              this.newUser.pdfButton.link = result.data.data[0].filename;
            })
            .catch((error) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  this.$i18n.locale == "tr"
                    ? "İşlem sırasında hata oluştu"
                    : "Getting error while process",
                showConfirmButton: false,
                timer: 1000,
              });
            });
        }
        let newHighlights = this.newUser.highlights.filter(
          (highlight) => highlight.file != null
        );
        if (newHighlights.length > 0) {
          for (let i = 0; i < newHighlights.length; i++) {
            if (newHighlights[i].file) {
              let formData = new FormData();
              formData.append("files", newHighlights[i].file);
              await HTTP.post("/files", formData, {
                headers: { "Content-Type": "multipart/form-data;" },
              })
                .then((result) => {
                  let item = this.newUser.highlights.filter(
                    (highlight) => highlight.file == newHighlights[i].file
                  )[0];
                  item.image = result.data.data[0].filename;
                  delete item.file;
                })
                .catch((error) => {
                  Swal.fire({
                    position: "center",
                    icon: "error",
                    title:
                      this.$i18n.locale == "tr"
                        ? "İşlem sırasında hata oluştu"
                        : "Getting error while process",
                    showConfirmButton: false,
                    timer: 1000,
                  });
                });
            }
          }
        }
      } catch (e) {
        return e;
      }
    },
    async updateUser() {
      this.xhrUpdate = true;
      const result = await this.checkFilesAndUpload();

      HTTP.put("/users/me", this.newUser)
        .then((result) => {
          console.log(
            "🚀 ~ file: new.vue ~ line 887 ~ HTTP.put ~ result",
            result
          );
          Swal.fire({
            position: "center",
            icon: "success",
            title:
              this.$i18n.locale == "tr"
                ? "Kullanıcı başarıyla güncellendi"
                : "User updated successfully.",
            showConfirmButton: false,
            timer: 1000,
          });
          this.xhrUpdate = false;
        })
        .catch((e) => {
          console.log(
            "🚀 ~ file: new.vue ~ line 887 ~ HTTP.put ~ result",
            e.response.status
          );
          this.xhrUpdate = false;
          if (e.response.status == 409) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                this.$i18n.locale == "tr"
                  ? "Bu e-posta veya cep telefonu zaten var"
                  : "This email or mobile phone already exists",
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                this.$i18n.locale == "tr"
                  ? "İşlem sırasında hata oluştu"
                  : "Getting error while process",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },

    changePassword() {
      this.$v.newPassword.$touch();
      if (this.$v.newPassword.$invalid) {
        return;
      } else {
        let data = {
          userId: this.newUser.id,
          password: this.newPassword,
        };

        HTTP.patch("users/update-password", data)
          .then(() => {
            this.changePasswordModal = false;
            this.$v.$reset();
            Swal.fire({
              icon: "success",
              text:
                this.$i18n.locale == "tr"
                  ? "Başarıyla Güncellendi"
                  : "Successfully Updated.",
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "warning",
              text:
                this.$i18n.locale == "tr"
                  ? "Lütfen Formu Kontrol Edin"
                  : "Please check form",
            });
          });
      }
    },
    personelVideoFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files) {
        if (this.checkFileSize(files[0])) {
          this.personelVideo = files[0];
        }
      }
    },
    promotionalVideoFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      console.log(
        "🚀 ~ file: Edit.vue ~ line 1711 ~ promotionalVideoFileChange ~ files",
        files
      );
      if (files) {
        if (this.checkFileSize(files[0])) {
          this.promotionalVideo = files[0];
          this.youtubeLink = null;
        }
      }
    },
    promotionalPDFFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files) {
        if (this.checkFileSize(files[0])) {
          this.promotionalPDF = files[0];
        }
      }
    },
    highlightFileChange(e, index) {
      var files = e.target.files || e.dataTransfer.files;
      if (files) {
        if (this.checkFileSize(files[0])) {
          this.newUser.highlights[index].file = files[0];
          this.newUser.highlights[index].link = null;
        }
      }
    },
    newHighLightImage(index) {
      return this.newHighlights.filter(
        (highlight) => highlight.index == index
      )[0];
    },
    checkFileSize(file) {
      let maxSize = this.$maxFileSize;
      if (file.size > maxSize) {
        Swal.fire({
          icon: "warning",
          text:
            this.$i18n.locale == "tr"
              ? "Dosya boyutu çok büyük. Maksimum dosya boyutu:"
              : "File size is too big. Max file size is " +
                Math.floor(maxSize / 1000000) +
                "MB",
        });
        return false;
      }
      return true;
    },
  },
  watch: {
    youtubeLink: function () {
      if (this.youtubeLink) {
        this.newUser.promotionalVideo = this.youtubeLink;
        this.promotionalVideo = null;
      }
    },
    image: function () {
      this.profileImage = this.image[0];
      let maxSize = 1024 * 1024 * 12;
      if (this.profileImage.file.size < maxSize) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.newProfileImageTemp = e.target.result;
        };
        reader.readAsDataURL(this.image[0].file);
      } else {
        Swal.fire({
          icon: "warning",
          text:
            this.$i18n.locale == "tr"
              ? "Dosya boyutu çok büyük. Maksimum dosya boyutu:"
              : "File size is too big. Max file size is " +
                Math.floor(maxSize / 1000000) +
                "MB",
        });
      }
    },
    changePasswordModal: function () {
      if (this.changePasswordModal) {
        this.newPassword = null;
        this.$v.newPassword.$reset();
      }
    },
  },
};
</script>
