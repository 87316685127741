import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import VueUploadComponent from "vue-upload-component";
// SCSS
import "@/assets/scss/style.scss";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueI18n from "vue-i18n";

import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS
Vue.component("vue-simple-suggest", VueSimpleSuggest);

import Notifications from "vue-notification";
Vue.use(Notifications);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import QrcodeVue from "qrcode.vue";

Vue.use(VueI18n);

// Plugins
Vue.use(Vuelidate);

// Components
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("file-upload", VueUploadComponent);
Vue.component("v-select", vSelect);
Vue.component("qrcode-vue", QrcodeVue);
// Files Path
// It's means max file size is 3MB
Vue.prototype.$maxFileSize = 1024 * 1024 * 3;

// Prototypes
// Vue.prototype.$hostURL = "http://localhost:8888";
// Vue.prototype.$apiURL = "http://172.16.0.5:8080/";
// Vue.prototype.$filesURL = "http://172.16.0.5:8080/files/";
// Vue.prototype.$appURL = "http://localhost:8080/";
// Vue.prototype.$webURL = "http://localhost:8888/";

// Live
Vue.prototype.$hostURL = "https://quicktouch.com.tr/";
Vue.prototype.$apiURL = "https://api.quicktouch.com.tr/";
Vue.prototype.$filesURL = "https://api.quicktouch.com.tr/files/";
Vue.prototype.$appURL = "https://app.quicktouch.com.tr/"
Vue.prototype.$webURL = "https://quicktouch.com.tr/"

Vue.prototype.$authenticatedUser =
  JSON.parse(localStorage.getItem("user")) || null;

Vue.prototype.$socialMediaList = [
  {
    label: "LinkedIn",
    slug: "LinkedIn",
  },
  {
    label: "Instagram",
    slug: "Instagram",
  },
  {
    label: "Twitter",
    slug: "Twitter",
  },
  {
    label: "TikTok",
    slug: "TikTok",
  },
  {
    label: "YouTube",
    slug: "YouTube",
  },
  {
    label: "Facebook",
    slug: "Facebook",
  },
  {
    label: "Pinterest",
    slug: "Pinterest",
  },
  {
    label: "WeChat",
    slug: "WeChat",
  },
  {
    label: "QQ",
    slug: "QQ",
  },
  {
    label: "Tumblr",
    slug: "Tumblr",
  },
  {
    label: "Qzone",
    slug: "Qzone",
  },
  {
    label: "Reddit",
    slug: "Reddit",
  },
  {
    label: "Baidu Tieba",
    slug: "Tieba",
  },
  {
    label: "Viber",
    slug: "Viber",
  },
  {
    label: "Snapchat",
    slug: "Snapchat",
  },
  {
    label: "Line",
    slug: "Line",
  },
  {
    label: "Telegram",
    slug: "Telegram",
  },
  {
    label: "Medium",
    slug: "Medium",
  },
  {
    label: "Skype",
    slug: "Skype",
  },
  {
    label: "Vimeo",
    slug: "Vimeo",
  },
  {
    label: "Behance",
    slug: "Behance",
  },
  {
    label: "500px",
    slug: "500px",
  },
  {
    label: "Blogger",
    slug: "Blogger",
  },
  {
    label: "Flickr",
    slug: "Flickr",
  },
];

Vue.config.productionTip = false;

// TEST
const messages = {
  tr: {
    profile: {
      share: "Paylaş",
      login: "Giriş",
      adress: "Adres",
      adressinfo: "Bilgileri",
      contract: "Sözleşme",
      save: "Kaydet",
      attention: "Dikkat",
      highlights: "Öne Çıkanlar",
      other: "Diğer",
      informations: "Bilgiler",
      payment: "Ödeme",
      bank: "Banka",
      watch: "Tanıtım videosunu izle",
      promotinolvideo: "Tanıtım videosu",
      videoerr: "Your browser does not support the video tag.",
      next: "Sonraki",
      previous: "Önceki",
      qr: "Show QR Code",
      social: "Sosyal",
      media: "Medyalar",
      close: "Kapat",


    },
    setup: {
      confirmheading: "Telefonunuzu Onaylayın",
      confirmdesc: "Onay kodunuzu gönderdik.",
      timerdone: "Süre doldu. Lütfen tekrar deneyin.",
      codeinvalid: "Girdiğiniz kod hatalı.",
      loginerr: "Yanlış email veya şifre",
      pass: "Şifre",
      login: "Giriş",
      passreset: "Şifre Sıfırlama",
      passcheck: "Ünlemli alanları kontrol edin",
      passcontrol: "Kod Alanını Kontrol Edin",
      passcode: "Kod",
      newpass: "Yeni Şifre",
      passforgot: "Şifremi Unuttum",
      forgotdesc: "Lütfen mesaj kutunuzu kontrol edin",
      checktel: "Lütfen telefon numaranızı kontrol edin",
      setprofile: "Profilini düzenle",
      acikriza: "Açık Rıza Menti",
      aydinlatma: "Aydınlatma Metni",
      setquick: "QuickTouch'ınızı Ayarlayın",
      verifynum: "Numaranızı doğrulayın",
      read: "'ni okudum kabul ediyorum.",
      secure: "Hesabını güvene al",
      required: "Zorunlu",
      secmail: "Kurtarma Emaili",
      createprofile: "Profilini oluştur",
      type:"Tip",
      value: "Değer",
      name: "İsim",
      code: "Kod",
      create: "Oluştur",
      changepassword: "Şifreni Degiştir",
      cancel: "Vazgeç",
      submit: "Gönder",
      accdelete: "Hesabını Sil",
      accdeletedesc: "Hesabını silmek istediğine emin misin?",
      accdeletedesctwo: "Eğer eminseniz, hesabınız 30 gün içinde silinecek..",
      accdeletebtn: "Hesabı sil",
      notsure: "Emin değilim",
      accreco: "Hesap kurtarma",
      accrecodesc: "Hesabını kurtamk istediğine emin misin?",
      recover: "Kurtar",
      personelvideo: "Video",
      firm: "Atanan Firma:",
      language: "Dil",
      title: "Başlık",
      promotinolvideo: "Tanıtım Videosu ",
      videoupload: "Video yükleme",
      promotinolpdf: "Promosyon PDF'i",
      pdfrev: " PDF'i İncele ",
      pdfbtn: "Promosyon PDF'inin buton texti",
      highlightstext: "Bilgi yok, aşağıdaki + düğmesini kullanarak bilgi ekleyebilirsiniz.",
      social: "Sosyal Medya Hesapları",
      additional: "Ek Bilgiler",
      adresses: "Adresler",
      adress: "Adres",
      bankinfos: "Banka Bilgileri",
      recipientname: "Hesap Sahibi",
      update: "Güncelle",
      buybcard: "Ek Kart Satın Al",
      vprofile: "Profili Görüntüle",
      logout: "Çıkış Yap",
      highlightsimage: "Seçilecek fotografın 645x280 gibi yatay formatta olmasına dikkat edin",

    }
  },
  en: {
    profile: {
      share: "Share",
      login: "Login",
      adress: "Adress",
      adressinfo: "Information",
      contract: "Contract",
      save: "Save Contact",
      attention: "Attention",
      highlights: "Highlights",
      other: "Other",
      informations: "Informations",
      payment: "Payment",
      bank: "Bank",
      accountholder: "Account Holder",
      watch: "Watch the promotional video",
      promotinolvideo: "Promotion Video",
      videoerr: "Your browser does not support the video tag.",
      next: "Next",
      previous: "Previous",
      qr: "Show QR Code",
      social: "Social",
      media: "Media",
      close: "Kapat",


    },
    setup: {
      confirmheading: "Confirm Your Phone",
      confirmdesc: "We've sent your code.",
      timerdone: "Time is done. Please resend to code.",
      codeinvalid: "Code is invalid",
      loginerr: "Wrong email or password",
      pass: "Password",
      login: "Login",
      type:"Type",
      passreset: "Password Reset",
      passcheck: "Check for exclamation points",
      passcontrol: "Please check code area",
      passcode: "Code",
      newpass: "New Pass",
      passforgot: "Forgot password",
      forgotdesc: "Please check your message box",
      checktel: "Please check your phone number",
      setprofile: "Set Your Profile",
      acikriza: "Explicit Consent Text",
      aydinlatma: "Obligation of Data Controller to Inform",
      setquick: "Set your QuickTouch",
      verifynum: "Verify your number",
      read: "I have read and accept.",
      secure: "Secure Your Account",
      required: "Required",
      secmail: "Recovery email",
      createprofile: "Create Your Profile",
      name: "Name",
      code: "Code",
      create: "Create",
      changepassword: "Change Password",
      cancel: "Cancel",
      submit: "Submit",
      accdelete: "User Account Delete",
      accdeletedesc: "Are you sure about delete to your account?",
      accdeletedesctwo: "If you sure your account will be deleted in 30 days.",
      accdeletebtn: "Delete Account",
      notsure: "I'm not sure",
      accreco: "User Account Recovery",
      accrecodesc: "Are you sure about recovery to your account?",
      recover: "Recover",
      personelvideo: "Personel Video",
      firm: "Assigned Firm:",
      language: "Language",
      value: "Value",
      title: "Title",
      promotinolvideo: "Promotional Video",
      videoupload: "Video Upload",
      promotinolpdf: "Promotional PDF",
      pdfrev: "Review PDF ",
      pdfbtn: "Promotional PDF Button Text",
      highlightstext: "No info, you can add info using below + button. ",
      social: "Social Accounts",
      additional: "Additional Infos",
      adresses: "Adresses",
      adress: "Adress",
      bankinfos: "Bank Infos",
      recipientname: "Recipient Name",
      update: "Update",
      buybcard: "Buy Backup Card",
      vprofile: "View Profile",
      logout: "Logout",
      highlightsimage: "The photo to be selected should not attract attention in a horizontal format such as 645x280",







    }
  },
};
// DİL ÇEVİRMEK İÇİN
/* <button @click="$i18n.locale = 'en'">aa</button> */

const i18n = new VueI18n({
  locale: "tr",
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
