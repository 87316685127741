<template>
  <div class="bg">
    <BgSetup />
    <VerifyArea />
    <Bottom />
  </div>
</template>
<script>
import BgSetup from "@/components/setup/BgSetup.vue";
import VerifyArea from "@/components/setup/VerifyArea.vue";

import Bottom from "@/components/Bottom.vue";

export default {
  name: "Verify",
  components: {
    BgSetup,
    Bottom,
    VerifyArea,
  },
};
</script>
