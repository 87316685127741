<template>
  <div class="row info-section">
    <h4 class="section-heading" v-if="parsedaddresses.length > 0">
      {{ $t("profile.adress") }} <span> {{ $t("profile.adressinfo") }}</span>
    </h4>
    <div
      class="box d-flex flex-column adress-section"
      v-if="parsedaddresses.length > 0"
    >
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <div
          class="accordion-item"
          v-for="(parsedaddress, index) in parsedaddresses"
          :key="index"
        >
          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button
              class="accordion-button"
              :class="{ collapsed: index != 0 }"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#panelsStayOpen-collapseOne' + index"
              aria-expanded="true"
              :aria-controls="'panelsStayOpen-collapseOne' + index"
            >
              {{ parsedaddress.title }}
            </button>
          </h2>
          <div
            :id="'panelsStayOpen-collapseOne' + index"
            class="accordion-collapse collapse"
            :class="{ show: index === 0 }"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div class="accordion-body">
              {{ parsedaddress.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Other",
  props: ["addresses"],
  data() {
    return {
      parsedaddresses: [],
    };
  },
  created() {
    this.parsedaddresses = JSON.parse(this.addresses).filter((item) => {
      return item.isActive == true;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
