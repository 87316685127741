<template>
  <div class="row info-section" >
    <h4 class="section-heading" v-if="parsedsocialAccounts.length > 0" >{{ $t("profile.social") }} <span>{{ $t("profile.media") }} </span></h4>
   <div class="social-icons d-flex justify-content-around" >
      <a :href="parsedsocialAccount.link" v-for="(parsedsocialAccount, index) in parsedsocialAccounts" :key="index" target="_blank">
        <img  v-if="parsedsocialAccount.type.slug === 'Facebook'" src="@/assets/images/fb.svg" />
        <img  v-if="parsedsocialAccount.type.slug === 'Instagram'" src="@/assets/images/inst.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Twitter'" src="@/assets/images/tw.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Pinterest'" src="@/assets/images/pin.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'YouTube'" src="@/assets/images/yt.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Reddit'" src="@/assets/images/reddit.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'WeChat'" src="@/assets/images/wechat.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'LinkedIn'" src="@/assets/images/linkedin.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'TikTok'" src="@/assets/images/tiktok.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'QQ'" src="@/assets/images/qq.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Tumblr'" src="@/assets/images/tumblr.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Qzone'" src="@/assets/images/qzone.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Baidu Tieba'" src="@/assets/images/baidu.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Viber'" src="@/assets/images/viber.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Snapchat'" src="@/assets/images/snapchat.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Line'" src="@/assets/images/line.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Telegram'" src="@/assets/images/telegram.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Medium'" src="@/assets/images/medium.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Vimeo'" src="@/assets/images/vimeo.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Behance'" src="@/assets/images/behance.svg" />
        <img  v-if="parsedsocialAccount.type.slug == '500px'" src="@/assets/images/500px.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Blogger'" src="@/assets/images/blogger.svg" />
        <img  v-if="parsedsocialAccount.type.slug == 'Flicker'" src="@/assets/images/flick.svg" />
      </a>

    </div>
  </div>
</template>

<script>
export default {
  name: "SocialArea",
  props: ["socialAccounts"], 
  data() {
    return {
      parsedsocialAccounts: [],
    };
  },
   created() {
    this.parsedsocialAccounts = JSON.parse(this.socialAccounts).filter(account=>account.isActive == true);
   }
};
</script>
