<template>
  <div class="bg">
    <BgSetup />
    <NewPassArea />
    <Bottom />

  </div>
</template>
<script>
import BgSetup from "@/components/setup/BgSetup.vue";
import NewPassArea from "@/components/setup/NewPassArea.vue";

import Bottom from "@/components/Bottom.vue";

export default {
  name: "NewPass",
  components: {
    BgSetup,
    NewPassArea,
    Bottom,
  },
};
</script>
