<template>
  <div class="row info-section">
    <h4 class="section-heading" v-if="parsedadditionalInfos.length > 0">
      {{ $t("profile.other") }} <span>{{ $t("profile.informations") }} </span>
    </h4>
    <div
      class="other-info box d-flex flex-column"
      v-if="parsedadditionalInfos.length > 0"
    >
      <a
        v-for="(parsedadditionalInfo, index) in parsedadditionalInfos"
        :href="setLink(parsedadditionalInfo)"
        :key="index"
        target="_blank"
      >
        <img
          v-if="parsedadditionalInfo.type.label == 'Email'"
          src="@/assets/images/mail.svg"
          alt=""
        />
        <img
          v-if="parsedadditionalInfo.type.label == 'Simple Link'"
          src="@/assets/images/site.svg"
          alt=""
        />
        <img
          v-if="parsedadditionalInfo.type.label == 'Phone'"
          src="@/assets/images/phone.svg"
          alt=""
        />
        <img
          v-if="parsedadditionalInfo.type.label == 'Fax'"
          src="@/assets/images/fax.svg"
          alt=""
        />
        {{ parsedadditionalInfo.value }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Other",
  props: ["additionalInfos"],
  data() {
    return {
      parsedadditionalInfos: [],
    };
  },
  created() {
    this.parsedadditionalInfos = JSON.parse(this.additionalInfos).filter(
      (item) => {
        return item.isActive == true;
      }
    );
  },
  methods: {
    setLink(parsedadditionalInfo) {
      let result = "";
      switch (parsedadditionalInfo.type.label) {
        case "Email":
          result = "mailto:" + parsedadditionalInfo.value;
          break;
        case "Simple Link":
          result = parsedadditionalInfo.value;
          break;
        case "Phone":
          result = "tel:" + parsedadditionalInfo.value;
          break;
        default:
          break;
      }
      return result;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
