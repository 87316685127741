<template>
  <div class="bg">
    <BgSetup />
    <!-- <Sendcodearea @sendcode="sendCode" /> -->

    <component
      :is="activeComponent"
      @sendcode="sendCode"
      @setNewPassword="setNewPassword"
      @timerdone="sendOTPAgain"
      :errors="errors"
      :isAgain="isAgain"
    ></component>

    <Bottom />
  </div>
</template>
<script>
import { HTTP } from "@/main-source";

import BgSetup from "@/components/setup/BgSetup.vue";
import Sendcodearea from "@/components/setup/Sendcodearea.vue";
import NewPassArea from "@/components/setup/NewPassArea.vue";
import router from "@/router";

import Bottom from "@/components/Bottom.vue";

export default {
  name: "Verify",
  data() {
    return {
      phoneNumber: null,
      activeComponent: "Sendcodearea",
      errors: false,
      isAgain: false,
    };
  },
  components: {
    BgSetup,
    Bottom,
    Sendcodearea,
    NewPassArea,
  },
  methods: {
    sendCode(data) {
      HTTP.post("/users/otp", data)
        .then((result) => {
          if (result.status == 200) {
            // router.push({
            //   name: "Newpass",
            // });
            this.phoneNumber = data.tel;
            this.activeComponent = "NewPassArea";
             this.error = false;
          this.errors = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          this.errors = true;

          this.validtel = null;
        });
    },
    setNewPassword(data) {
      let postdata = {
        tel: this.phoneNumber,
        code: data.code,
        password: data.pass,
      };
      console.log("postdata", postdata);
      HTTP.put("/users/otp/password", postdata)
        .then((result) => {
          if (result.status == 204) {
            router.push({
              name: "Login",
              params: {
                passwordsucess: true,
              },
            });
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.errors = true;
        });
    },
    sendOTPAgain() {
      this.activeComponent = "Sendcodearea";
      this.isAgain = true;
    },
  },
};
</script>
