<template>
  <div class="box-area">
    <div class="container">
      <div class="verify-area confirm">
        <span class="s-heading"> {{ $t("setup.confirmheading") }}</span>
        <span class="s-desc">{{ $t("setup.confirmdesc") }}
          <span class="count-down">{{ timerCount }}</span></span
        >
        <div v-if="timerdone" class="notice" role="alert">
          <span class="s-desc"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-exclamation-triangle"
            >
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
              ></path>
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
              ></path></svg
            >{{ $t("setup.timerdone") }}</span
          >
        </div>
        <div v-if="coderr" class="notice" role="alert">
          <span class="s-desc"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-exclamation-triangle"
            >
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
              ></path>
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
              ></path></svg
            >{{ $t("setup.codeinvalid") }}</span
          >
        </div>
        <input
          type="tel"
          placeholder="123456"
          maxlength="6"
          v-model="code"
          pattern="[0-9]*"
          inputmode="numeric"
          v-mask="'######'"
          autocomplete="one-time-code"
        />

        <btn-setup
          v-if="!timerdone"
          :btnText="$i18n.locale == 'tr' ? 'Devam' : 'Cofirm'"
          class="negative-button"
          @click.native="test"
        ></btn-setup>
        <btn-setup
          v-if="timerdone"
          :btnText="$i18n.locale == 'tr' ? 'Tekrar Gönder' : 'Resend'"
          class="negative-button"
          @click.native="resend"
        ></btn-setup>
      </div>
    </div>
  </div>
</template>

<script>
import BtnSetup from "@/components/setup/BtnSetup.vue";
import { HTTP } from "@/main-source";
import { mask } from "vue-the-mask";
import router from "@/router";
import { required, email } from "vuelidate/lib/validators";

export default {
  directives: { mask },

  data() {
    return {
      timerdone: false,
      timerCount: 180,
      shortlink: this.$route.params.shortlink || null,
      code: null,
      userData: null,
      xhrRequest: true,
      coderr: false,
      password: null,
      secondEmail: null,
    };
  },
  name: "Confirm",
  components: {
    BtnSetup,
  },
  validations: {
    email: { required, email },
    password: { required },
    secondEmail: { required },
  },
  created() {
    // Check shortlink and if is not redirect to home
    if (this.shortlink == null) {
      window.location = this.$hostURL;
    }

    const url = "/users/profile/" + this.shortlink;

    HTTP.get(url)
      .then((result) => {
        this.userData = result.data;
        this.xhrRequest = false;
      })
      .then(() => {
        if (this.userData.isVerified) {
          router.push({
            name: "Home",
            params: {
              shortlink: this.shortlink,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.coderr = true;
      });
  },
  methods: {
    test() {
      console.log(this.timerdone);
      let data = {
        shortlink: this.shortlink,
        code: this.code,
      };

      HTTP.post("/users/verify", data)
        .then((result) => {
          if (result.status == 200) {
            localStorage.setItem("user", JSON.stringify(result.data));
            HTTP.defaults.headers.common.Authorization =
              "Bearer " + result.data.token;
            router.push({
              name: "Secure",
              params: {
                shortlink: result.data.shortlink,
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.coderr = true;
        });
    },
    resend() {
      let data = {
        tel: this.userData.tel,
        shortlink: this.shortlink,
      };
      console.log("🚀 ~ file: VerifyArea.vue ~ line 64 ~ test ~ data", data);

      HTTP.post("/users/start", data)
        .then((result) => {
          if (result.status == 200) {
            this.timerdone = false;
            this.timerCount = 180;
          }
        })
        .catch((error) => {
          console.log(error);
          this.coderr = true;
        });
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.timerdone = true;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>
