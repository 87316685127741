<template>
  <div
    id="carouselExampleControls"
    class="carousel slide"
    data-bs-ride="carousel"
    v-if="highlightsList"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item"
        :class="{ active: index === 0 }"
        v-for="(highlight, index) in highlightsList"
        :key="index"
      >
        <a target="_blank" :href="highlight.link"
          ><img :src="$filesURL + highlight.image" class="d-block w-100"
        /></a>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden"> {{ $t("profile.previous") }}</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden"> {{ $t("profile.next") }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: ["highlights"],
  data() {
    return {
      highlightsList: [],
    };
  },
  created() {
    this.highlightsList = this.highlights;

        for (var i = 0; i < this.highlightsList.length; i++){
      const first = this.highlightsList[i].link.substring(0,3);
      if (first == "htt"){
          return
      }
      else {
        this.highlightsList[i].link = 'https://' + this.highlightsList[i].link;
      }
    }
  },
};
</script>
