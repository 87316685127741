<template>
  <div class="box-area">
    <div class="container">
      <div class="verify-area login-area">
        <span class="s-heading">{{ $t("setup.login") }}</span>
        <div v-if="error" class="notice" role="alert">
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-exclamation-triangle"
            >
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
              ></path>
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
              ></path></svg
            >{{ $t("setup.loginerr") }}
          </span>
        </div>
        <span>Email *</span>
        <div class="input-group mb-3 login-input">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="14"
                height="14"
                viewBox="0 0 14 14"
              >
                <image
                  id="_001-user"
                  data-name="001-user"
                  width="14"
                  height="14"
                  opacity="0.624"
                  xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABuwAAAbsBOuzj4gAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAUbSURBVHic5ZvBa1RHHMc/s2EloEmhmpKkbSKFmmColBQJiJemh9KaUBqv6UEUPAhe/At6iEdPHhSvvXmQYEnx0IaCIIgtiUVtDg3bdpUa6qGmQWUlvx7eLC5vf7P7dt689zb1C8PCvPd+v+/vOzNvZn5v1ogIecAYsxeYBT4B3gGGbQF4ZEsV+B64LiJPciEmIpkVoATMA8vAS0ASlpf2mXmglCnHDIM/BtztIGhXuQsc2zECAH3AYoDA42UR6OtqAYDRQK3eqjeMhuRsLPHUMMZMAD8Ab7W47Z5tyWXgT6IXH0Qvw3eBj4EvgIkWNjaAaRG5l5YzEKYHAAPAOu6WWwYOd2DvsH3GZW8dGOiKIQDsAm46iD4Fjqewfdza0GzfBHZ1gwDnHQR/AyYC2J+wtjQf5wsVABgCthRij4GREF3U+hmxNuN+toChIgW4pJB6DhwJFXyDryPWdtzfpUIEAAaBmkJoIXTwDT4XFH81YLAIAU4rZDaA/gwF6Lc+4n5P+9os4Y8vlbqLIvI0hc2WsLYvJuSSCF4LIWNMH/AEKMcuHRKRX3zJJPT9AdGKsBE1YK+IbHZqz7cHvE9z8JWsgwewPiqx6rLl1DF8BRhW6sIsTZNB86VxaouQAjxS6rKC5itXAd5U6v72tOUDzZfGqS18BfhLqdvnacsHmi+NU1v4CvC7UufVBT2h+dI4tYWvABWlrtUePjQ0XxUfQ74CVIHtWN1+O0dnCutjf6x623LqGF4CiEgNuK1c8l6RdQDNx23LqXOkWJefpHv2AieL2AztATYVMnnvBjeBPbkLYAldUQjlnQ+4kspuSlLjwDOFVF4ZoWfAeGECWHJnFWJ55QTPprYfgKABbjgIZpkVvoHdzhcqgCU65Hg718syYb8LbJAyGVovIb8MjQPf0bxIaUSIL0MV4DMR+TUdY4sQKja03CBwB3fLpS13SJEAzbQH1GGM2U301ebDoIZhBTgqIlshjaZJijbBGPMpsET44LE2l6yPcAjQ7Q0wR7ZdXxsKcxQ5C9jAvwLu5xh4vNy3HLyF8E2LvwF8A8wkfKRKNDTWgYdEb//6L0SzwNsNv+8BnxMdpkqCb4F5Efkn4f2v4NHyB4E12rfOCvA1MJmil01aGysJ/K0BBzMdAkTjTtsBNparwFjasan4HrO2W/neBOaCC0A0WywQZV5czn8EpkIHrnCZsr5cPLYt10TH65I47AGutXBYAWazDlzhNWt9u3hdA3pCCHChTasHOavjKcJAm95wIZUAwIkWxi8D5aKCb+BYtlxcPE94CQAcBV4oBmvAmaIDV/ieQT+w8YJoCZ1cAKIDj1oGRrox+JgIGufHOA5YugzdcnX7ooNMIIJrONxKJADRXO964RU+5hMIUG7xYmxaI8Qf7gEeKA9Winzbe4gwgD5FPiA2NcYfPOVQLvd5PoAIs45YTqkCAL1Em5amrl90MClE0IZCFejVBDjnUCzz5W2GAkw5YjqnCbCq3Hi16CACiKBtoFbr142IYIwZQT9gMC4ia0r9joExZgzQMsijIvJHPSeoJTZWd3rwADaGVeXSDLxKimoCLGZFqgBoscxAlNfbTXTqqjd2w0ci8nPGxHKBMWYS+ClW/RzYVwKmaQ6++n8JHsDGEj9C0wtMl4ADyjNLmbPKH1pMB0roR87WMyZTBLSYhktEX3bjeJgxmSKgxTTk6gF5nvvNC+r54te+BxjsKavYhX7x+PNBN8P+ySP+b5Z//wOqogHhayKfiQAAAABJRU5ErkJggg=="
                />
              </svg>
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="Email"
            aria-label="Username"
            aria-describedby="basic-addon1"
            v-model="user.email"
            @keyup.enter="login"
            @blur="
              $v.user.email.$touch();
              error = false;
            "
            :class="{
              'is-invalid': $v.user.email.$error,
            }"
          />
        </div>
        <span>{{ $t("setup.pass") }}*</span>

        <div class="input-group mb-3 login-input">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <image
                  id="_002-padlock"
                  data-name="002-padlock"
                  width="18"
                  height="18"
                  opacity="0.624"
                  xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAPPSURBVHic7ZtLSFVBGMd/pln5yArFMpBoVSpUC9uECFEJZbmoqE0viktBqyAQCipqVdsWLSJ6QS+Q0EByI5UVqZSRkRspF6mF9MA0EssWc8w5cx+e25wzc+TeHwzM3DP3+77533vPmcd3IcXJsOg7H8hx6qPAsI0gTApQCdQC1UA5UKhcHwK6gcdAE9BpMLbAmA0cQAxsIsnSDexzbMxIqoC3JD9wtbwB1hmOXYsM4BQwTvxBDQDtQKNTOpzX4vUfB05i957liSzgGrEH8Q6oB1YmeH+Z06cnjo2rjo9QkgFcITroj8Ahkgs8C4gA/THsXSak34TjRAfbBhRr2CwEWmPYPaYVaQCsBsZwB3kHyPbBdjZwV7E9BqzywbYvZADPcAf4HJjro495iJum7OOJj/a12Iw7sK/AkgD8LAW+K75qAvCTNC24g6oP0NcJxVdzgL48UYL7eT/I1Pw+CHKBT5K/cWCxjsFZmgHVAJlSuwGxsAmKEeC+1M4ENuoY1BVAnaI2adrzQqPSrjLgMy5Pcf8m5xvwWUCIngYfpEBMrud/SH7f6xjS/QnkSfVBTVvJMCDV83UM6Qogz+9/atpKBtmX1uJIV4AZT1oA2wHYJi2A7QBsk/IC/M8jpBjYCVTgXviUIjYuTFAq1XOAS4jd43vA5yAdH8E9CwtbGUbsJQZCJAQD9FoO+j34RUTvxoS5fAMWehmY13tAHe6VXhdwFPec3CYlwEWmNkoLgG2IMwpfuIBb4U1+GfaRGtwxnvfyJq+PwVylHZZPXqZfaefF7KWQ8vOAlBfA5kFjPrDcqfci5hfGsfENWAbcRmSEdDllCLiFe4ZnBNMCVCByAXbhPjecA+xGpMWUmQzIpABZiLm6mhskU4RYT2Qm6OMrJgWoBVZI7V+Iicp1pz5JObDFVFAmBahW2hFgPyIR6vA0fQPDpADqoUmHVG9XrhUEHMs/TArQq7S3S/Ud0/QNDJMCtCjts8BL4BVwRnp9AnhoKiiTAnQiTo9l1iDSa2QaEMIYwfQ8YC/wKMH1VsRN0RimBRgB1iOEkPMIRoE9wAanjzFsTIX/ADcQ099JhoCbzjWjpPxqMC2A7QAcJmw5tilAT5y6UWxuiEQQeX8A52wFYVOAPgI8xfFKWO4B1kgL4LHfb6XtRxq836gxqTHHxKsAagrcVo/vM0md0lYPSmLi9a8na4EXymtthOeEqITotN1KfP7vYTP2T329lgd+DnySIkQWhu3BTVdek3jnWYs8xO5NXwgGqpYPwGmiD3ITovP3swWIxIkw8AWRFJEmWf4Cm7br9rcST9IAAAAASUVORK5CYII="
                />
              </svg>
            </span>
          </div>
          <input
            :type="passInput"
            class="form-control"
            :placeholder="$i18n.locale == 'tr' ? 'Şifre' : 'Password'"
            aria-label="Username"
            aria-describedby="basic-addon1"
            v-model="user.password"
            @keyup.enter="login"
            @blur="
              $v.user.password.$touch();
              error = false;
            "
            :class="{
              'is-invalid': $v.user.password.$error,
            }"
          />
          <button v-on:click="showPass" class="show-pass-btn">
            <svg v-if="passInput == 'password'"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
              />
              <path
                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
              />
            </svg>
            <svg v-if="passInput == 'text'"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eye-slash"
              viewBox="0 0 16 16"
            >
              <path
                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
              />
              <path
                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
              />
              <path
                d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
              />
            </svg>
          </button>
        </div>
        <btn-setup
          :btnText="$i18n.locale == 'tr' ? 'Giriş' : 'Login'"
          class="negative-button"
          @click.native="login"
        ></btn-setup>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import BtnSetup from "@/components/setup/BtnSetup.vue";
import router from "@/router";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "LoginArea",
  props: ["passwordsucess"],
  components: {
    BtnSetup,
  },
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      error: false,
      passInput: "password",
    };
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
  },
  created() {
    if (this.$authenticatedUser) {
      if (this.$authenticatedUser.shortlink) {
        router.push({
          name: "Edit",
          params: { shortlink: this.$authenticatedUser.shortlink },
        });
      }
    }
  },
  methods: {
    showPass() {
      if (this.passInput == "password") {
        this.passInput = "text";
      } else {
        this.passInput = "password";
      }
    },
    login() {
      this.$v.user.email.$touch();
      this.$v.user.password.$touch();
      if (this.$v.user.password.$invalid || this.$v.user.email.$invalid) {
        return;
      } else {
        HTTP.post("/users/signin", this.user)
          .then((result) => {
            if (result.status == 202) {
              localStorage.setItem("user", JSON.stringify(result.data));
              HTTP.defaults.headers.common.Authorization =
                "Bearer " + result.data.token;
              setTimeout(() => {
                router.push({
                  name: "Edit",
                  params: {
                    shortlink: result.data.shortlink,
                  },
                });
              }, 500);
            }
          })
          .catch((er) => {
            if (er.response.status == 401) {
              this.badCredentials = true;
              this.error = true;
            }
            if (er.response.status == 404) {
              this.error = true;
            }
            return false;
          });
      }
    },
  },
};
</script>
